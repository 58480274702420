import { string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { BrandingIconCard, NamedLink } from '../../../../components';
import { injectIntl } from '../../../../util/reactIntl';
import css from './CustomerFooter.module.css';
import classNames from 'classnames';
import IconCollection from '../../../../components/IconCollection/IconCollection';

const CustomerFooter = (props) => {
    const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);
    const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

    const { intl } = props;
    return (
        <div className={classNames(css.customerFooterWrapper, arabicLanguage && css.arabicDirection)}>
            <div className={css.footerBox}>
                <div className={css.footerTopLeft}>
                    <div className={css.logo}>
                    <NamedLink name="LandingPage">
                    <IconCollection name="customer_logo" />
                    </NamedLink>
                    </div>
                    <div className={css.bookingDeals}>
                        <FormattedMessage id="CustomerFooter.appointmentBooking" />
                    </div>
                </div>
                <div className={css.footerTopRight}>
                    <NamedLink name="LandingPage">
                        {intl.formatMessage({ id: 'CustomerFooter.home' })}
                    </NamedLink>
                    <NamedLink name="AlgoliaSearchPage" params={{ listingType: 'deal' }}>
                        {intl.formatMessage({ id: 'CustomerFooter.deals' })}
                    </NamedLink>
                    <NamedLink name="AlgoliaSearchPage" params={{ listingType: 'services' }}>
                        {intl.formatMessage({ id: 'CustomerFooter.services' })}
                    </NamedLink>
                    {!isAuthenticated ? (
                        <NamedLink name="LandingPageMerchant" >
                            {intl.formatMessage({ id: 'CustomerFooter.becomeAMerchant' })}
                        </NamedLink>
                    ) : null}
                    {/* Make this a link when we have a contact us page */}
                    <span>
                        {intl.formatMessage({ id: 'CustomerFooter.contactUs' })}
                    </span>
                </div>
            </div>
            <div className={css.footerBottom}>
                <div className={css.footerBottomLeft}>
                    <div className={css.leftText}>
                        {intl.formatMessage({ id: 'CustomerFooter.copyright' })}
                    </div>
                    <div className={css.leftText}>
                        {intl.formatMessage({ id: 'CustomerFooter.privacyPolicy' })}
                    </div>
                    <div className={css.leftText}>
                        {intl.formatMessage({ id: 'CustomerFooter.termsOfUse' })}
                    </div>
                </div>
                <div className={css.footerBottomRight}>
                    <span>
                        <BrandingIconCard type="footerinstagram" />
                    </span>
                    <span>
                        <BrandingIconCard type="footerfacebook" />
                    </span>
                    <span>
                        <BrandingIconCard type="footeryoutube" />
                    </span>
                </div>
            </div>
        </div>
    )
};

CustomerFooter.defaultProps = {
    className: null,
    rootClassName: null,
};

CustomerFooter.propTypes = {
    className: string,
    rootClassName: string,
};

export default injectIntl(CustomerFooter);
