import classNames from 'classnames';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import React from 'react';
import { NamedLink } from '../../../../components';

import BlockBuilder from '../../BlockBuilder';
import Field from '../../Field';

import IconCollection from '../../../../components/IconCollection/IconCollection';
import MobileStore from '../../../../components/MobileStore/MobileStore';
import { FormattedMessage, injectIntl } from '../../../../util/reactIntl';
import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    linkLogoToExternalSite,
    merchantPage,
    intl,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  // use block builder instead of mapping blocks manually
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem('language') === 'Arabic';

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={classNames(className || css.root, arabicLanguage && css.arabicText)}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={classNames(css.footer, merchantPage && css.merchantFooter)}>
        <div className={classNames(css.content, getContentCss(numberOfColumns))}>
          <div>
            {/* <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
              linkToExternalSite={linkLogoToExternalSite}
              layout={logoLayout}
            /> */}
            <NamedLink name="LandingPage" className={css.footerLogo}> 
              {/* <IconCollection name="footerLogo" /> */}
              {/* <ResponsiveImage
                    alt="Logo"
                    image={newLogo}
                    variants={"custom-image"}
                    className={css.logoIcon}
                /> */}
           {merchantPage ?  <IconCollection name="vendor_logo" /> :  <IconCollection name="customer_logo" />}
            </NamedLink>
          </div>
          <div className={css.sloganMobile}>
            {/* <Field data={slogan} className={css.slogan} /> */}
            <span className={css.slogan}>
              <FormattedMessage id="SectionFooter.slogan" />
            </span>
          </div>
          <div className={css.detailsInfo}>
            <div className={css.sloganDesktop}>
              {/* <Field data={slogan} className={css.slogan} /> */}
              <span className={css.slogan}>
                <FormattedMessage id="SectionFooter.slogan" />
              </span>
            </div>
            {/* {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
              </div>
            ) : null} */}
            {/* <Field data={copyright} className={css.copyright} /> */}
            <span className={css.copyright}>
              <FormattedMessage id="SectionFooter.copyright" />
            </span>
          </div>

          {merchantPage ? (
            <div className={css.merchantPageFooter}>
              <MobileStore className={css.footerMobileStore} />
              <div className={classNames(css.grid, css.gridCol2)}>
                <div className={css.navLinks}>
                  <ul>
                    <li>
                      <NamedLink className={css.link} name="LandingPageMerchant">
                        {intl.formatMessage({ id: 'SectionFooter.home' })}
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink className={css.link} name="LandingPage">
                        {intl.formatMessage({ id: 'SectionFooter.forCustomers' })}
                      </NamedLink>
                    </li>
                    <li>
                      <span className={css.link}>
                        {intl.formatMessage({ id: 'SectionFooter.merchants' })}
                      </span>
                    </li>
                    <li>
                      <span className={css.link}>
                        {intl.formatMessage({ id: 'SectionFooter.features' })}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className={css.navLinks}>
                  <ul>
                    <li>
                      <span className={css.link}>
                        {intl.formatMessage({ id: 'SectionFooter.aboutUs' })}
                      </span>
                    </li>
                    <li>
                      <span className={css.link}>
                        {intl.formatMessage({ id: 'SectionFooter.careers' })}
                      </span>
                    </li>
                    <li>
                      <NamedLink className={css.link} name="PrivacyPolicyPage">
                        {intl.formatMessage({ id: 'SectionFooter.privacyPolicy' })}
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink className={css.link} name="TermsOfServicePage">
                        {intl.formatMessage({ id: 'SectionFooter.termsOfService' })}
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
              <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
            </div>
          )}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default injectIntl(SectionFooter);
