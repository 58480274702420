import algoliasearch from 'algoliasearch/lite';
import classNames from 'classnames';
import { bool, func, number, object, oneOf, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Configure, Hits, Index, InstantSearch } from 'react-instantsearch';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import aa from 'search-insights';
import { Modal } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { intlShape, useIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/uiHelpers';
import { parse } from '../../util/urlHelpers';
// import AlgoliaFilterPanel from '../AlgoliaSearchPage/AlgoliaFilterPanel';
import {
  createSearchResultSchema,
  searchParamsPicker,
} from '../AlgoliaSearchPage/AlgoliaSearchPage.helpers';
import css from '../AlgoliaSearchPage/AlgoliaSearchPage.module.css';
import AlgoliaSearchDealCard from '../AlogoliaSearchCard/AlgoliaSearchDealCard';
import AlgoliaSearchServiceCard from '../AlogoliaSearchCard/AlgoliaSearchServiceCard';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { getAlgoliasearchResults } from './LandingPage.duck';
import { toggleFavorite } from '../WishlistPage/WishlistPage.duck';

// Initialize Algolia search client
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  {
    _useRequestCache: true,
  }
);

// Initialize Algolia Insights
aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
});

// Panel component for search filters
const Panel = ({ children, header, footer }) => (
  <div className="ais-Panel">
    {header && <div className="ais-Panel-header">{header}</div>}
    <div className="ais-Panel-body">{children}</div>
    {footer && <div className="ais-Panel-footer">{footer}</div>}
  </div>
);

// Determine Algolia indices based on pathname
const getAlgoliaIndices = pathname => {
  switch (pathname) {
    case '/':
      return [process.env.REACT_APP_ALGOLIA_DEALS_INDEX];
    case '/deals':
      return [process.env.REACT_APP_ALGOLIA_DEALS_INDEX];
    case '/services':
      return [process.env.REACT_APP_ALGOLIA_SERVICE_INDEX];
    case '/staffs':
      return [process.env.REACT_APP_ALGOLIA_STAFF_INDEX];
    case '/product':
      return [process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX];
    default:
      return [process.env.REACT_APP_ALGOLIA_DEALS_INDEX];
  }
};

// Component to render hits based on index
const Hit = ({ hit, indexName, currentUser, onToggleFavorite, history, routeConfiguration }) => {
  switch (indexName) {
    case process.env.REACT_APP_ALGOLIA_DEALS_INDEX:
      return (
        <AlgoliaSearchDealCard
          hit={hit}
          roleName={indexName}
          currentUser={currentUser}
          toggleFavorite={onToggleFavorite}
          landingPage={'landingPage'}
          history={history}
          routeConfiguration={routeConfiguration}
        />
      );
    case process.env.REACT_APP_ALGOLIA_SERVICE_INDEX:
      return (
        <AlgoliaSearchServiceCard
          hit={hit}
          roleName={indexName}
          currentUser={currentUser}
          toggleFavorite={onToggleFavorite}
          landingPage={'landingPage'}
          history={history}
          routeConfiguration={routeConfiguration}
        />
      );
    default:
      return null;
  }
};

// Main component for Algolia Search Page
const AlgoliaSearchPageComponent = props => {
  const {
    intl,
    config,
    location,
    listings,
    currentUser,
    routeConfiguration,
    onUpdateProfile,
    onManageDisableScrolling,
    onToggleFavorite,
    history,
    dealFilterParams={}
  } = props;
  // Parse search parameters
  const searchParams = useMemo(() => parse(location.search), [location.search]);
  const initialQuery = searchParams.keywords || '';
  const initialPage = searchParams.page ? parseInt(searchParams.page, 10) : 0;

  // State for search parameters
  const [searchState, setSearchState] = useState({ query: initialQuery, page: initialPage });
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  // Determine Algolia indices based on current pathname
  const indices = getAlgoliaIndices(location?.pathname);

  // Handle browser back/forward navigation
  useEffect(() => {
    const handlePopState = ({ state }) => {
      setSearchState({ ...searchState, state });
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [searchState]);


  // Extract search configuration
  const { listingFields: listingFieldsConfig } = config?.listing || {};
  const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

  // Prepare search parameters for Algolia
  const { searchParamsInURL } = searchParamsPicker(
    location.search,
    searchParams,
    listingFieldsConfig,
    defaultFiltersConfig,
    sortConfig,
    false
  );

  // Generate schema for search results
  createSearchResultSchema(listings, searchParamsInURL || {}, intl, routeConfiguration, config);

  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";
  return (
    <div className={css.container}>
      <div className={classNames(css.searchWrapper)}>
        <InstantSearch
          future={{ preserveSharedStateOnUnmount: true }}
          searchClient={searchClient}
          insights={true}
          initialUiState={indices.reduce((acc, indexName) => {
            acc[indexName] = { page: initialPage };
            return acc;
          }, {})}
        >
          {indices.map(indexName => (
            <Index key={indexName} indexName={indexName}>
              <Configure
                hitsPerPage={indexName === process.env.REACT_APP_ALGOLIA_DEALS_INDEX ? 12 : 6}
                ruleContexts={[]}
                {
                  ...(dealFilterParams?.location?.lat && dealFilterParams?.location?.lng && {
                    aroundLatLng: `${dealFilterParams.location.lat},${dealFilterParams.location.lng}`,
                  })
                }
                filters={[
                  dealFilterParams?.priceRange?.min !== undefined && dealFilterParams?.priceRange?.max !== undefined
                    ? `price.amount > ${dealFilterParams?.priceRange.min * 100} AND price.amount < ${dealFilterParams?.priceRange.max * 100}`
                    : '',
                ]
                  .filter(Boolean)
                  .join(' AND ')}
                facetFilters={
                  dealFilterParams?.category ? [`publicData.categoryLevel1:${dealFilterParams?.category}`] : undefined
                }
              />
           <div className={classNames(css.searchContents, arabicLanguage && css.arabicDirection)}>
                <section className="container-results"></section>
                <div className={css.searchFilter}>
                  <div className={classNames(css.filterWrapper, css.filterDesktop)}>
                    <div>
                      <div className={css.faqsec}>
                        <div className={css.faqwrapper}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    css.aisInstantSearch,
                    css.aisInstantSearchFullWidth,
                    currentUser ? '' : css.notLoginUserSearch
                  )}
                >
                  <Modal
                    isOpen={isMobileFilterOpen}
                    onClose={() => setIsMobileFilterOpen(false)}
                    onManageDisableScrolling={onManageDisableScrolling}
                    modalTitle="Filters"
                    id="AlgoliasSearchPage.filterModal"
                    className={css.filterWrapper}
                  >
                    <div className={classNames(css.filterWrapper)}>
                      <div>
                        <div className={css.faqsec}>
                          <div className={css.faqwrapper}>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  <div
                    className={classNames(
                      !searchState?.refinementList || !searchState?.productDev?.refinementList
                        ? css.smallMargin
                        : null,
                      css.Resultcontainer
                    )}
                  >
                    <div
                      className={classNames(
                        searchState?.refinementList || searchState?.productDev?.refinementList
                          ? !searchState?.refinementList
                            ? css.show5card
                            : null
                          : css.show5card
                      )}
                    >
                      <Hits
                        hitComponent={hitProps => (
                          <Hit
                            {...hitProps}
                            indexName={indexName}
                            currentUser={currentUser}
                            onToggleFavorite={onToggleFavorite}
                            history={history}
                            routeConfiguration={routeConfiguration}
                          />
                        )}
                        className={indexName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Index>
          ))}
        </InstantSearch>
      </div>
    </div>
  );
};

// Default props
AlgoliaSearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  activeListingId: null,
  initialSearchFormValues: {},
};

// Prop types
AlgoliaSearchPageComponent.propTypes = {
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  initialSearchFormValues: object,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

// Enhanced component with viewport and redux connection
const EnhancedAlgoliaSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return props.searchInProgress ? (
    <div className={css.loader_overlay}>
      <div className={css.loader}></div>
    </div>
  ) : (
    <AlgoliaSearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

// Map Redux state to props
const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
  } = state.user;
  const { searchInProgress, algoliaSearchResults } = state.AlgoliaSearchPage || {};
  const { updateInProgress } = state.ProfileSettingsPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
    updateInProgress,
    algoliaSearchResults,
    searchInProgress,
  };
};

// Map dispatch functions to props
const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onGetAlgoliasearchResults: params => dispatch(getAlgoliasearchResults(params)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onToggleFavorite: (listingId, type, user) => dispatch(toggleFavorite(listingId, type, user)),
});

// Connect component to Redux and with viewport
const CustomerAlgoliaPage = compose(
  withViewport,
  connect(mapStateToProps, mapDispatchToProps)
)(EnhancedAlgoliaSearchPage);

export default CustomerAlgoliaPage;
