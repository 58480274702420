// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
  { code: 'AF', en: 'Afghanistan', fr: 'Afghanistan', es: 'Afganistán', de: 'Afghanistan', ar: 'أفغانستان' },
  { code: 'AX', en: 'Åland Islands', fr: 'Îles Åland', es: 'Islas Áland', de: 'Åland', ar: 'جزر أولاند' },
  { code: 'AL', en: 'Albania', fr: 'Albanie', es: 'Albania', de: 'Albanien', ar: 'ألبانيا' },
  { code: 'DZ', en: 'Algeria', fr: 'Algérie', es: 'Argel', de: 'Algerien', ar: 'الجزائر' },
  { code: 'AS', en: 'American Samoa', fr: 'Samoa américaines', es: 'Samoa Americana', de: 'Amerikanisch-Samoa', ar: 'ساموا الأمريكية' },
  { code: 'AD', en: 'Andorra', fr: 'Andorre', es: 'Andorra', de: 'Andorra', ar: 'أندورا' },
  { code: 'AO', en: 'Angola', fr: 'Angola', es: 'Angola', de: 'Angola', ar: 'أنغولا' },
  { code: 'AI', en: 'Anguilla', fr: 'Anguilla', es: 'Anguila', de: 'Anguilla', ar: 'أنغيلا' },
  { code: 'AQ', en: 'Antarctica', fr: 'Antarctique', es: 'Antártida', de: 'Antarktika', ar: 'القارة القطبية الجنوبية' },
  { code: 'AG', en: 'Antigua and Barbuda', fr: 'Antigua-et-Barbuda', es: 'Antigua y Barbuda', de: 'Antigua und Barbuda', ar: 'أنتيغوا وبربودا' },
  { code: 'AR', en: 'Argentina', fr: 'Argentine', es: 'Argentina', de: 'Argentinien', ar: 'الأرجنتين' },
  { code: 'AM', en: 'Armenia', fr: 'Arménie', es: 'Armenia', de: 'Armenien', ar: 'أرمينيا' },
  { code: 'AW', en: 'Aruba', fr: 'Aruba', es: 'Aruba', de: 'Aruba', ar: 'أروبا' },
  { code: 'AU', en: 'Australia', fr: 'Australie', es: 'Australia', de: 'Australien', ar: 'أستراليا' },
  { code: 'AT', en: 'Austria', fr: 'Autriche', es: 'Austria', de: 'Österreich', ar: 'النمسا' },
  { code: 'AZ', en: 'Azerbaijan', fr: 'Azerbaïdjan', es: 'Azerbaiyán', de: 'Aserbaidschan', ar: 'أذربيجان' },
  { code: 'BS', en: 'Bahamas', fr: 'Bahamas', es: 'Bahamas', de: 'Bahamas', ar: 'باهاماس' },
  { code: 'BH', en: 'Bahrain', fr: 'Bahreïn', es: 'Bahréin', de: 'Bahrain', ar: 'البحرين' },
  { code: 'BD', en: 'Bangladesh', fr: 'Bangladesh', es: 'Bangladesh', de: 'Bangladesch', ar: 'بنغلاديش' },
  { code: 'BB', en: 'Barbados', fr: 'Barbade', es: 'Barbados', de: 'Barbados', ar: 'باربادوس' },
  { code: 'BY', en: 'Belarus', fr: 'Biélorussie', es: 'Belarús', de: 'Belarus', ar: 'روسيا البيضاء' },
  { code: 'BE', en: 'Belgium', fr: 'Belgique', es: 'Bélgica', de: 'Belgien', ar: 'بلجيكا' },
  { code: 'BZ', en: 'Belize', fr: 'Belize', es: 'Belice', de: 'Belize', ar: 'بيليز' },
  { code: 'BJ', en: 'Benin', fr: 'Bénin', es: 'Benin', de: 'Benin', ar: 'بنين' },
  { code: 'BM', en: 'Bermuda', fr: 'Bermudes', es: 'Bermudas', de: 'Bermuda', ar: 'برمودا' },
  { code: 'BT', en: 'Bhutan', fr: 'Bhoutan', es: 'Bhután', de: 'Bhutan', ar: 'بوتان' },
  { code: 'BO', en: 'Bolivia', fr: 'Bolivie', es: 'Bolivia', de: 'Bolivien', ar: 'بوليفيا' },
  { code: 'BQ', en: 'Bonaire, Sint Eustatius and Saba', fr: 'Pays-Bas caribéens', es: 'Caribe Neerlandés', de: 'Bonaire, Sint Eustatius und Saba', ar: 'بونير، سينت أوستاتيوس وصابا' },
  { code: 'BA', en: 'Bosnia and Herzegovina', fr: 'Bosnie-Herzégovine', es: 'Bosnia y Herzegovina', de: 'Bosnien und Herzegowina', ar: 'البوسنة والهرسك' },
  { code: 'BW', en: 'Botswana', fr: 'Botswana', es: 'Botsuana', de: 'Botswana', ar: 'بوتسوانا' },
  { code: 'BV', en: 'Bouvet Island', fr: 'Île Bouvet', es: 'Isla Bouvet', de: 'Bouvetinsel', ar: 'جزيرة بوفو' },
  { code: 'BR', en: 'Brazil', fr: 'Brésil', es: 'Brasil', de: 'Brasilien', ar: 'البرازيل' },
  { code: 'IO', en: 'British Indian Ocean Territory', fr: 'Territoire britannique de l’Océan Indien', es: 'Territorio Británico del Océano Índico', de: 'Britisches Territorium im Indischen Ozean', ar: 'إقليم المحيط الهندي البريطاني' },
  { code: 'BN', en: 'Brunei Darussalam', fr: 'Brunei Darussalam', es: 'Brunéi', de: 'Brunei Darussalam', ar: 'بروناي دار السلام' },
  { code: 'BG', en: 'Bulgaria', fr: 'Bulgarie', es: 'Bulgaria', de: 'Bulgarien', ar: 'بلغاريا' },
  { code: 'BF', en: 'Burkina Faso', fr: 'Burkina Faso', es: 'Burkina Faso', de: 'Burkina Faso', ar: 'بوركينا فاسو' },
  { code: 'BI', en: 'Burundi', fr: 'Burundi', es: 'Burundi', de: 'Burundi', ar: 'بوروندي' },
  { code: 'KH', en: 'Cambodia', fr: 'Cambodge', es: 'Camboya', de: 'Kambodscha', ar: 'كمبوديا' },
  { code: 'CM', en: 'Cameroon', fr: 'Cameroun', es: 'Camerún', de: 'Kamerun', ar: 'الكاميرون' },
  { code: 'CA', en: 'Canada', fr: 'Canada', es: 'Canadá', de: 'Kanada', ar: 'كندا' },
  { code: 'CV', en: 'Cape Verde', fr: 'Cap-Vert', es: 'Cabo Verde', de: 'Kap Verde', ar: 'الرأس الأخضر' },
  { code: 'KY', en: 'Cayman Islands', fr: 'Iles Cayman', es: 'Islas Caimán', de: 'Kaimaninseln', ar: 'جزر كايمان' },
  { code: 'CF', en: 'Central African Republic', fr: 'République centrafricaine', es: 'República Centro-Africana', de: 'Zentralafrikanische Republik', ar: 'جمهورية أفريقيا الوسطى' },
  { code: 'TD', en: 'Chad', fr: 'Tchad', es: 'Chad', de: 'Tschad', ar: 'تشاد' },
  { code: 'CL', en: 'Chile', fr: 'Chili', es: 'Chile', de: 'Chile', ar: 'تشيلي' },
  { code: 'CN', en: 'China', fr: 'Chine', es: 'China', de: 'China, Volksrepublik', ar: 'الصين' },
  { code: 'CX', en: 'Christmas Island', fr: 'Île Christmas', es: 'Islas Christmas', de: 'Weihnachtsinsel', ar: 'جزيرة عيد الميلاد' },
  { code: 'CC', en: 'Cocos (Keeling) Islands', fr: 'Îles Cocos', es: 'Islas Cocos', de: 'Kokosinseln', ar: 'جزر كوكوس (كيلينغ)' },
  { code: 'CO', en: 'Colombia', fr: 'Colombie', es: 'Colombia', de: 'Kolumbien', ar: 'كولومبيا' },
  { code: 'KM', en: 'Comoros', fr: 'Comores', es: 'Comoros', de: 'Komoren', ar: 'جزر القمر' },
  { code: 'CG', en: 'Congo', fr: 'République du Congo', es: 'Congo', de: 'Kongo, Republik', ar: 'الكونغو' },
  { code: 'CD', en: 'Congo, the Democratic Republic of the', fr: 'République démocratique du Congo', es: 'República democrática del Congo', de: 'Kongo, Demokratische Republik', ar: 'جمهورية الكونغو الديمقراطية' },
  { code: 'CK', en: 'Cook Islands', fr: 'Îles Cook', es: 'Islas Cook', de: 'Cookinseln', ar: 'جزر كوك' },
  { code: 'CR', en: 'Costa Rica', fr: 'Costa Rica', es: 'Costa Rica', de: 'Costa Rica', ar: 'كوستاريكا' },
  { code: 'CI', en: 'Côte d\'Ivoire', fr: 'Côte d’Ivoire', es: 'Costa de Marfil', de: 'Côte d’Ivoire', ar: 'ساحل العاج' },
  { code: 'HR', en: 'Croatia', fr: 'Croatie', es: 'Croacia', de: 'Kroatien', ar: 'كرواتيا' },
  { code: 'CU', en: 'Cuba', fr: 'Cuba', es: 'Cuba', de: 'Kuba', ar: 'كوبا' },
  { code: 'CW', en: 'Curaçao', fr: 'Curaçao', es: 'Curazao', de: 'Curaçao', ar: 'كوراساو' },
  { code: 'CY', en: 'Cyprus', fr: 'Chypre', es: 'Chipre', de: 'Zypern', ar: 'قبرص' },
  { code: 'CZ', en: 'Czech Republic', fr: 'République tchèque', es: 'República Checa', de: 'Tschechien', ar: 'جمهورية التشيك' },
  { code: 'DK', en: 'Denmark', fr: 'Danemark', es: 'Dinamarca', de: 'Dänemark', ar: 'الدنمارك' },
  { code: 'DJ', en: 'Djibouti', fr: 'Djibouti', es: 'Yibuti', de: 'Dschibuti', ar: 'جيبوتي' },
  { code: 'DM', en: 'Dominica', fr: 'Dominique', es: 'Domínica', de: 'Dominica', ar: 'دومينيكا' },
  { code: 'DO', en: 'Dominican Republic', fr: 'République dominicaine', es: 'República Dominicana', de: 'Dominikanische Republik', ar: 'جمهورية الدومينيكان' },
  { code: 'EC', en: 'Ecuador', fr: 'Équateur', es: 'Ecuador', de: 'Ecuador', ar: 'الإكوادور' },
  { code: 'EG', en: 'Egypt', fr: 'Égypte', es: 'Egipto', de: 'Ägypten', ar: 'مصر' },
  { code: 'SV', en: 'El Salvador', fr: 'Salvador', es: 'El Salvador', de: 'El Salvador', ar: 'السلفادور' },
  { code: 'GQ', en: 'Equatorial Guinea', fr: 'Guinée équatoriale', es: 'Guinea Ecuatorial', de: 'Äquatorialguinea', ar: 'غينيا الاستوائية' },
  { code: 'ER', en: 'Eritrea', fr: 'Érythrée', es: 'Eritrea', de: 'Eritrea', ar: 'إريتريا' },
  { code: 'EE', en: 'Estonia', fr: 'Estonie', es: 'Estonia', de: 'Estland', ar: 'إستونيا' },
  { code: 'ET', en: 'Ethiopia', fr: 'Éthiopie', es: 'Etiopía', de: 'Äthiopien', ar: 'إثيوبيا' },
  { code: 'FK', en: 'Falkland Islands (Malvinas)', fr: 'Îles Falkland', es: 'Islas Malvinas', de: 'Falklandinseln', ar: 'جزر فوكلاند (مالفيناس)' },
  { code: 'FO', en: 'Faroe Islands', fr: 'Îles Féroé', es: 'Islas Faroe', de: 'Färöer', ar: 'جزر فارو' },
  { code: 'FJ', en: 'Fiji', fr: 'Fidji', es: 'Fiji', de: 'Fidschi', ar: 'فيجي' },
  { code: 'FI', en: 'Finland', fr: 'Finlande', es: 'Finlandia', de: 'Finnland', ar: 'فنلندا' },
  { code: 'FR', en: 'France', fr: 'France', es: 'Francia', de: 'Frankreich', ar: 'فرنسا' },
  { code: 'GF', en: 'French Guiana', fr: 'Guyane française', es: 'Guayana Francesa', de: 'Französisch-Guayana', ar: 'غويانا الفرنسية' },
  { code: 'PF', en: 'French Polynesia', fr: 'Polynésie française', es: 'Polinesia Francesa', de: 'Französisch-Polynesien', ar: 'بولينيزيا الفرنسية' },
  { code: 'TF', en: 'French Southern Territories', fr: 'Terres australes et antarctiques françaises', es: 'Territorios Australes Franceses', de: 'Französische Süd- und Antarktisgebiete', ar: 'الأراضي الفرنسية الجنوبية' },
  { code: 'GA', en: 'Gabon', fr: 'Gabon', es: 'Gabón', de: 'Gabun', ar: 'الغابون' },
  { code: 'GM', en: 'Gambia', fr: 'Gambie', es: 'Gambia', de: 'Gambia', ar: 'غامبيا' },
  { code: 'GE', en: 'Georgia', fr: 'Géorgie', es: 'Georgia', de: 'Georgien', ar: 'جورجيا' },
  { code: 'DE', en: 'Germany', fr: 'Allemagne', es: 'Alemania', de: 'Deutschland', ar: 'ألمانيا' },
  { code: 'GH', en: 'Ghana', fr: 'Ghana', es: 'Ghana', de: 'Ghana', ar: 'غانا' },
  { code: 'GI', en: 'Gibraltar', fr: 'Gibraltar', es: 'Gibraltar', de: 'Gibraltar', ar: 'جبل طارق' },
  { code: 'GR', en: 'Greece', fr: 'Grèce', es: 'Grecia', de: 'Griechenland', ar: 'اليونان' },
  { code: 'GL', en: 'Greenland', fr: 'Groenland', es: 'Groenlandia', de: 'Grönland', ar: 'غرينلاند' },
  { code: 'GD', en: 'Grenada', fr: 'Grenade', es: 'Granada', de: 'Grenada', ar: 'غرينادا' },
  { code: 'GP', en: 'Guadeloupe', fr: 'Guadeloupe', es: 'Guadalupe', de: 'Guadeloupe', ar: 'غوادلوب' },
  { code: 'GU', en: 'Guam', fr: 'Guam', es: 'Guam', de: 'Guam', ar: 'غوام' },
  { code: 'GT', en: 'Guatemala', fr: 'Guatemala', es: 'Guatemala', de: 'Guatemala', ar: 'غواتيمالا' },
  { code: 'GG', en: 'Guernsey', fr: 'Guernesey', es: 'Guernsey', de: 'Guernsey', ar: 'غيرنسي' },
  { code: 'GN', en: 'Guinea', fr: 'Guinée', es: 'Guinea', de: 'Guinea', ar: 'غينيا' },
  { code: 'GW', en: 'Guinea-Bissau', fr: 'Guinée-Bissau', es: 'Guinea-Bissau', de: 'Guinea-Bissau', ar: 'غينيا بيساو' },
  { code: 'GY', en: 'Guyana', fr: 'Guyane', es: 'Guayana', de: 'Guyana', ar: 'غويانا' },
  { code: 'HT', en: 'Haiti', fr: 'Haïti', es: 'Haití', de: 'Haiti', ar: 'هايتي' },
  { code: 'HM', en: 'Heard Island and McDonald Islands', fr: 'Îles Heard-et-MacDonald', es: 'Islas Heard y McDonald', de: 'Heard und McDonaldinseln', ar: 'جزيرة هيرد وجزر ماكدونالد' },
  { code: 'VA', en: 'Holy See (Vatican City State)', fr: 'Saint-Siège (Vatican)', es: 'Ciudad del Vaticano', de: 'Vatikanstadt', ar: 'الكرسي الرسولي (دولة الفاتيكان)' },
  { code: 'HN', en: 'Honduras', fr: 'Honduras', es: 'Honduras', de: 'Honduras', ar: 'هندوراس' },
  { code: 'HK', en: 'Hong Kong', fr: 'Hong Kong', es: 'Hong Kong', de: 'Hongkong', ar: 'هونغ كونغ' },
  { code: 'HU', en: 'Hungary', fr: 'Hongrie', es: 'Hungría', de: 'Ungarn', ar: 'هنغاريا' },
  { code: 'IS', en: 'Iceland', fr: 'Islande', es: 'Islandia', de: 'Island', ar: 'آيسلندا' },
  { code: 'IN', en: 'India', fr: 'Inde', es: 'India', de: 'Indien', ar: 'الهند' },
  { code: 'ID', en: 'Indonesia', fr: 'Indonésie', es: 'Indonesia', de: 'Indonesien', ar: 'إندونيسيا' },
  { code: 'IR', en: 'Iran, Islamic Republic of', fr: 'Iran', es: 'Irán', de: 'Iran, Islamische Republik', ar: 'إيران' },
  { code: 'IQ', en: 'Iraq', fr: 'Irak', es: 'Irak', de: 'Irak', ar: 'العراق' },
  { code: 'IE', en: 'Ireland', fr: 'Irlande', es: 'Irlanda', de: 'Irland', ar: 'أيرلندا' },
  { code: 'IM', en: 'Isle of Man', fr: 'Ile de Man', es: 'Isla de Man', de: 'Insel Man', ar: 'جزيرة مان' },
  { code: 'IL', en: 'Israel', fr: 'Israël', es: 'Israel', de: 'Israel', ar: 'إسرائيل' },
  { code: 'IT', en: 'Italy', fr: 'Italie', es: 'Italia', de: 'Italien', ar: 'إيطاليا' },
  { code: 'JM', en: 'Jamaica', fr: 'Jamaïque', es: 'Jamaica', de: 'Jamaika', ar: 'جامايكا' },
  { code: 'JP', en: 'Japan', fr: 'Japon', es: 'Japón', de: 'Japan', ar: 'اليابان' },
  { code: 'JE', en: 'Jersey', fr: 'Jersey', es: 'Jersey', de: 'Jersey (Kanalinsel)', ar: 'جيرسي' },
  { code: 'JO', en: 'Jordan', fr: 'Jordanie', es: 'Jordania', de: 'Jordanien', ar: 'الأردن' },
  { code: 'KZ', en: 'Kazakhstan', fr: 'Kazakhstan', es: 'Kazajstán', de: 'Kasachstan', ar: 'كازاخستان' },
  { code: 'KE', en: 'Kenya', fr: 'Kenya', es: 'Kenia', de: 'Kenia', ar: 'كينيا' },
  { code: 'KI', en: 'Kiribati', fr: 'Kiribati', es: 'Kiribati', de: 'Kiribati', ar: 'كيريباس' },
  { code: 'KP', en: 'Korea, Democratic People\'s Republic of', fr: 'Corée du Nord', es: 'Corea del Norte', de: 'Korea, Demokratische Volksrepublik (Nordkorea)', ar: 'كوريا الشمالية' },
  { code: 'KR', en: 'Korea, Republic of', fr: 'Corée du Sud', es: 'Corea del Sur', de: 'Korea, Republik (Südkorea)', ar: 'كوريا الجنوبية' },
  { code: 'KW', en: 'Kuwait', fr: 'Koweït', es: 'Kuwait', de: 'Kuwait', ar: 'الكويت' },
  { code: 'KG', en: 'Kyrgyzstan', fr: 'Kirghizistan', es: 'Kirguistán', de: 'Kirgisistan', ar: 'قيرغيزستان' },
  { code: 'LA', en: 'Laos', fr: 'Laos', es: 'Laos', de: 'Laos', ar: 'لاوس' },
  { code: 'LV', en: 'Latvia', fr: 'Lettonie', es: 'Letonia', de: 'Lettland', ar: 'لاتفيا' },
  { code: 'LB', en: 'Lebanon', fr: 'Liban', es: 'Líbano', de: 'Libanon', ar: 'لبنان' },
  { code: 'LS', en: 'Lesotho', fr: 'Lesotho', es: 'Lesotho', de: 'Lesotho', ar: 'ليسوتو' },
  { code: 'LR', en: 'Liberia', fr: 'Libéria', es: 'Liberia', de: 'Liberia', ar: 'ليبيريا' },
  { code: 'LY', en: 'Libya', fr: 'Libye', es: 'Libia', de: 'Libyen', ar: 'ليبيا' },
  { code: 'LI', en: 'Liechtenstein', fr: 'Liechtenstein', es: 'Liechtenstein', de: 'Liechtenstein', ar: 'ليختنشتاين' },
  { code: 'LT', en: 'Lithuania', fr: 'Lituanie', es: 'Lituania', de: 'Litauen', ar: 'ليتوانيا' },
  { code: 'LU', en: 'Luxembourg', fr: 'Luxembourg', es: 'Luxemburgo', de: 'Luxemburg', ar: 'لوكسمبورغ' },
  { code: 'MO', en: 'Macao', fr: 'Macao', es: 'Macao', de: 'Macau', ar: 'ماكاو' },
  { code: 'MK', en: 'North Macedonia', fr: 'Macédoine du Nord', es: 'Macedonia del Norte', de: 'Nordmazedonien', ar: 'جمهورية مقدونيا الشمالية' },
  { code: 'MG', en: 'Madagascar', fr: 'Madagascar', es: 'Madagascar', de: 'Madagaskar', ar: 'مدغشقر' },
  { code: 'MW', en: 'Malawi', fr: 'Malawi', es: 'Malawi', de: 'Malawi', ar: 'مالاوي' },
  { code: 'MY', en: 'Malaysia', fr: 'Malaisie', es: 'Malasia', de: 'Malaysia', ar: 'ماليزيا' },
  { code: 'MV', en: 'Maldives', fr: 'Maldives', es: 'Maldivas', de: 'Malediven', ar: 'المالديف' },
  { code: 'ML', en: 'Mali', fr: 'Mali', es: 'Mali', de: 'Mali', ar: 'مالي' },
  { code: 'MT', en: 'Malta', fr: 'Malte', es: 'Malta', de: 'Malta', ar: 'مالطا' },
  { code: 'MH', en: 'Marshall Islands', fr: 'Îles Marshall', es: 'Islas Marshall', de: 'Marshallinseln', ar: 'جزر مارشال' },
  { code: 'MQ', en: 'Martinique', fr: 'Martinique', es: 'Martinica', de: 'Martinique', ar: 'مارتينيك' },
  { code: 'MR', en: 'Mauritania', fr: 'Mauritanie', es: 'Mauritania', de: 'Mauretanien', ar: 'موريتانيا' },
  { code: 'MU', en: 'Mauritius', fr: 'Maurice', es: 'Mauricio', de: 'Mauritius', ar: 'موريشيوس' },
  { code: 'YT', en: 'Mayotte', fr: 'Mayotte', es: 'Mayotte', de: 'Mayotte', ar: 'مايوت' },
  { code: 'MX', en: 'Mexico', fr: 'Mexique', es: 'México', de: 'Mexiko', ar: 'المكسيك' },
  { code: 'FM', en: 'Micronesia, Federated States of', fr: 'Micronésie', es: 'Micronesia', de: 'Mikronesien', ar: 'ولايات ميكرونيزيا المتحدة' },
  { code: 'MD', en: 'Moldova', fr: 'Moldavie', es: 'Moldova', de: 'Moldawien', ar: 'مولدوفا' },
  { code: 'MC', en: 'Monaco', fr: 'Monaco', es: 'Mónaco', de: 'Monaco', ar: 'موناكو' },
  { code: 'MN', en: 'Mongolia', fr: 'Mongolie', es: 'Mongolia', de: 'Mongolei', ar: 'منغوليا' },
  { code: 'ME', en: 'Montenegro', fr: 'Monténégro', es: 'Montenegro', de: 'Montenegro', ar: 'الجبل الأسود' },
  { code: 'MS', en: 'Montserrat', fr: 'Montserrat', es: 'Montserrat', de: 'Montserrat', ar: 'مونتسيرات' },
  { code: 'MA', en: 'Morocco', fr: 'Maroc', es: 'Marruecos', de: 'Marokko', ar: 'المغرب' },
  { code: 'MZ', en: 'Mozambique', fr: 'Mozambique', es: 'Mozambique', de: 'Mosambik', ar: 'موزمبيق' },
  { code: 'MM', en: 'Myanmar', fr: 'Myanmar', es: 'Myanmar', de: 'Myanmar', ar: 'ميانمار' },
  { code: 'NA', en: 'Namibia', fr: 'Namibie', es: 'Namibia', de: 'Namibia', ar: 'ناميبيا' },
  { code: 'NR', en: 'Nauru', fr: 'Nauru', es: 'Nauru', de: 'Nauru', ar: 'ناورو' },
  { code: 'NP', en: 'Nepal', fr: 'Népal', es: 'Nepal', de: 'Nepal', ar: 'نيبال' },
  { code: 'NL', en: 'Netherlands', fr: 'Pays-Bas', es: 'Países Bajos', de: 'Niederlande', ar: 'هولندا' },
  { code: 'NC', en: 'New Caledonia', fr: 'Nouvelle-Calédonie', es: 'Nueva Caledonia', de: 'Neukaledonien', ar: 'كاليدونيا الجديدة' },
  { code: 'NZ', en: 'New Zealand', fr: 'Nouvelle-Zélande', es: 'Nueva Zelanda', de: 'Neuseeland', ar: 'نيوزيلندا' },
  { code: 'NI', en: 'Nicaragua', fr: 'Nicaragua', es: 'Nicaragua', de: 'Nicaragua', ar: 'نيكاراغوا' },
  { code: 'NE', en: 'Niger', fr: 'Niger', es: 'Níger', de: 'Niger', ar: 'النيجر' },
  { code: 'NG', en: 'Nigeria', fr: 'Nigeria', es: 'Nigeria', de: 'Nigeria', ar: 'نيجيريا' },
  { code: 'NU', en: 'Niue', fr: 'Niue', es: 'Niue', de: 'Niue', ar: 'نيوي' },
  { code: 'NF', en: 'Norfolk Island', fr: 'Île Norfolk', es: 'Islas Norkfolk', de: 'Norfolkinsel', ar: 'جزيرة نورفولك' },
  { code: 'MP', en: 'Northern Mariana Islands', fr: 'Îles Mariannes du Nord', es: 'Islas Marianas del Norte', de: 'Nördliche Marianen', ar: 'جزر ماريانا الشمالية' },
  { code: 'NO', en: 'Norway', fr: 'Norvège', es: 'Noruega', de: 'Norwegen', ar: 'النرويج' },
  { code: 'OM', en: 'Oman', fr: 'Oman', es: 'Omán', de: 'Oman', ar: 'عمان' },
  { code: 'PK', en: 'Pakistan', fr: 'Pakistan', es: 'Pakistán', de: 'Pakistan', ar: 'باكستان' },
  { code: 'PW', en: 'Palau', fr: 'Palau', es: 'Islas Palaos', de: 'Palau', ar: 'بالاو' },
  { code: 'PS', en: 'Palestine, State of', fr: 'Palestine', es: 'Palestina', de: 'Staat Palästina', ar: 'دولة فلسطين' },
  { code: 'PA', en: 'Panama', fr: 'Panama', es: 'Panamá', de: 'Panama', ar: 'بنما' },
  { code: 'PG', en: 'Papua New Guinea', fr: 'Papouasie-Nouvelle-Guinée', es: 'Papúa Nueva Guinea', de: 'Papua-Neuguinea', ar: 'بابوا غينيا الجديدة' },
  { code: 'PY', en: 'Paraguay', fr: 'Paraguay', es: 'Paraguay', de: 'Paraguay', ar: 'باراغواي' },
  { code: 'PE', en: 'Peru', fr: 'Pérou', es: 'Perú', de: 'Peru', ar: 'بيرو' },
  { code: 'PH', en: 'Philippines', fr: 'Philippines', es: 'Filipinas', de: 'Philippinen', ar: 'الفلبين' },
  { code: 'PN', en: 'Pitcairn', fr: 'Pitcairn', es: 'Islas Pitcairn', de: 'Pitcairninseln', ar: 'جزر بيتكيرن' },
  { code: 'PL', en: 'Poland', fr: 'Pologne', es: 'Polonia', de: 'Polen', ar: 'بولندا' },
  { code: 'PT', en: 'Portugal', fr: 'Portugal', es: 'Portugal', de: 'Portugal', ar: 'البرتغال' },
  { code: 'PR', en: 'Puerto Rico', fr: 'Puerto Rico', es: 'Puerto Rico', de: 'Puerto Rico', ar: 'بورتو ريكو' },
  { code: 'QA', en: 'Qatar', fr: 'Qatar', es: 'Qatar', de: 'Katar', ar: 'قطر' },
  { code: 'RE', en: 'Réunion', fr: 'Réunion', es: 'Reunión', de: 'Réunion', ar: 'ريونيون' },
  { code: 'RO', en: 'Romania', fr: 'Roumanie', es: 'Rumanía', de: 'Rumänien', ar: 'رومانيا' },
  { code: 'RU', en: 'Russian Federation', fr: 'Russie', es: 'Rusia', de: 'Russische Föderation', ar: 'الاتحاد الروسي' },
  { code: 'RW', en: 'Rwanda', fr: 'Rwanda', es: 'Ruanda', de: 'Ruanda', ar: 'رواندا' },
  { code: 'BL', en: 'Saint Barthélemy', fr: 'Saint-Barthélemy', es: 'San Bartolomé', de: 'Saint-Barthélemy', ar: 'سانت بارثيليمي' },
  { code: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha', fr: 'Sainte-Hélène', es: 'Santa Elena', de: 'St. Helena', ar: 'سانت هيلينا' },
  { code: 'KN', en: 'Saint Kitts and Nevis', fr: 'Saint-Kitts-et-Nevis', es: 'San Cristóbal y Nieves', de: 'St. Kitts und Nevis', ar: 'سانت كيتس ونيفيس' },
  { code: 'LC', en: 'Saint Lucia', fr: 'Sainte-Lucie', es: 'Santa Lucía', de: 'St. Lucia', ar: 'سانت لوسيا' },
  { code: 'MF', en: 'Saint Martin (French part)', fr: 'Saint-Martin (partie française)', es: 'San Martín (parte francesa)', de: 'Saint-Martin (franz. Teil)', ar: 'سانت مارتن (الجزء الفرنسي)' },
  { code: 'PM', en: 'Saint Pierre and Miquelon', fr: 'Saint-Pierre-et-Miquelon', es: 'San Pedro y Miquelón', de: 'Saint-Pierre und Miquelon', ar: 'سان بيير وميكلون' },
  { code: 'VC', en: 'Saint Vincent and the Grenadines', fr: 'Saint-Vincent-et-les Grenadines', es: 'San Vicente y las Granadinas', de: 'St. Vincent und die Grenadinen', ar: 'سانت فينسنت وغرنادين' },
  { code: 'WS', en: 'Samoa', fr: 'Samoa', es: 'Samoa', de: 'Samoa', ar: 'ساموا' },
  { code: 'SM', en: 'San Marino', fr: 'Saint-Marin', es: 'San Marino', de: 'San Marino', ar: 'سان مارينو' },
  { code: 'ST', en: 'Sao Tome and Principe', fr: 'Sao Tomé-et-Principe', es: 'Santo Tomé y Príncipe', de: 'São Tomé und Príncipe', ar: 'ساو تومي وبرينسيب' },
  { code: 'SA', en: 'Saudi Arabia', fr: 'Arabie Saoudite', es: 'Arabia Saudita', de: 'Saudi-Arabien', ar: 'المملكة العربية السعودية' },
  { code: 'SN', en: 'Senegal', fr: 'Sénégal', es: 'Senegal', de: 'Senegal', ar: 'السنغال' },
  { code: 'RS', en: 'Serbia', fr: 'Serbie', es: 'Serbia y Montenegro', de: 'Serbien', ar: 'صربيا' },
  { code: 'SC', en: 'Seychelles', fr: 'Seychelles', es: 'Seychelles', de: 'Seychellen', ar: 'سيشيل' },
  { code: 'SL', en: 'Sierra Leone', fr: 'Sierra Leone', es: 'Sierra Leona', de: 'Sierra Leone', ar: 'سيراليون' },
  { code: 'SG', en: 'Singapore', fr: 'Singapour', es: 'Singapur', de: 'Singapur', ar: 'سنغافورة' },
  { code: 'SX', en: 'Sint Maarten (Dutch part)', fr: 'Saint-Martin (partie néerlandaise)', es: 'San Martín (parte neerlandesa)', de: 'Sint Maarten (niederl. Teil)', ar: 'سانت مارتن (الجزء الهولندي)' },
  { code: 'SK', en: 'Slovakia', fr: 'Slovaquie', es: 'Eslovaquia', de: 'Slowakei', ar: 'سلوفاكيا' },
  { code: 'SI', en: 'Slovenia', fr: 'Slovénie', es: 'Eslovenia', de: 'Slowenien', ar: 'سلوفينيا' },
  { code: 'SB', en: 'Solomon Islands', fr: 'Îles Salomon', es: 'Islas Solomón', de: 'Salomonen', ar: 'جزر سليمان' },
  { code: 'SO', en: 'Somalia', fr: 'Somalie', es: 'Somalia', de: 'Somalia', ar: 'الصومال' },
  { code: 'ZA', en: 'South Africa', fr: 'Afrique du Sud', es: 'Sudáfrica', de: 'Südafrika', ar: 'جنوب أفريقيا' },
  { code: 'GS', en: 'South Georgia and the South Sandwich Islands', fr: 'Géorgie du Sud et les îles Sandwich du Sud', es: 'Georgia del Sur e Islas Sandwich deSur', de: 'Südgeorgien und die Südlichen Sandwichinseln', ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية' },
  { code: 'SS', en: 'South Sudan', fr: 'Sud-Soudan', es: 'Sudán del Sur', de: 'Südsudan', ar: 'جنوب السودان' },
  { code: 'ES', en: 'Spain', fr: 'Espagne', es: 'España', de: 'Spanien', ar: 'إسبانيا' },
  { code: 'LK', en: 'Sri Lanka', fr: 'Sri Lanka', es: 'Sri Lanka', de: 'Sri Lanka', ar: 'سريلانكا' },
  { code: 'SD', en: 'Sudan', fr: 'Soudan', es: 'Sudán', de: 'Sudan', ar: 'السودان' },
  { code: 'SR', en: 'Suriname', fr: 'Suriname', es: 'Surinam', de: 'Suriname', ar: 'سورينام' },
  { code: 'SJ', en: 'Svalbard and Jan Mayen', fr: 'Svalbard et Jan Mayen', es: 'Islas Svalbard y Jan Mayen', de: 'Svalbard und Jan Mayen', ar: 'سفالبارد ويان ماين' },
  { code: 'SZ', en: 'Swaziland', fr: 'Eswatini', es: 'Suazilandia', de: 'Swasiland', ar: 'سوازيلاند' },
  { code: 'SE', en: 'Sweden', fr: 'Suède', es: 'Suecia', de: 'Schweden', ar: 'السويد' },
  { code: 'CH', en: 'Switzerland', fr: 'Suisse', es: 'Suiza', de: 'Schweiz', ar: 'سويسرا' },
  { code: 'SY', en: 'Syrian Arab Republic', fr: 'Syrie', es: 'Siria', de: 'Syrien, Arabische Republik', ar: 'الجمهورية العربية السورية' },
  { code: 'TW', en: 'Taiwan', fr: 'Taiwan', es: 'Taiwán', de: 'Taiwan', ar: 'تايوان' },
  { code: 'TJ', en: 'Tajikistan', fr: 'Tadjikistan', es: 'Tayikistán', de: 'Tadschikistan', ar: 'طاجيكستان' },
  { code: 'TZ', en: 'Tanzania', fr: 'Tanzanie', es: 'Tanzania', de: 'Tansania', ar: 'تنزانيا' },
  { code: 'TH', en: 'Thailand', fr: 'Thaïlande', es: 'Tailandia', de: 'Thailand', ar: 'تايلاند' },
  { code: 'TL', en: 'Timor-Leste', fr: 'Timor-Leste', es: 'Timor-Leste', de: 'Osttimor ', ar: 'تيمور الشرقية' },
  { code: 'TG', en: 'Togo', fr: 'Togo', es: 'Togo', de: 'Togo', ar: 'توغو' },
  { code: 'TK', en: 'Tokelau', fr: 'Tokelau', es: 'Tokelau', de: 'Tokelau', ar: 'توكلاو' },
  { code: 'TO', en: 'Tonga', fr: 'Tonga', es: 'Tonga', de: 'Tonga', ar: 'تونغا' },
  { code: 'TT', en: 'Trinidad and Tobago', fr: 'Trinité-et-Tobago', es: 'Trinidad y Tobago', de: 'Trinidad und Tobago', ar: 'ترينيداد وتوباغو' },
  { code: 'TN', en: 'Tunisia', fr: 'Tunisie', es: 'Túnez', de: 'Tunesien', ar: 'تونس' },
  { code: 'TR', en: 'Turkey', fr: 'Turquie', es: 'Turquía', de: 'Türkei', ar: 'تركيا' },
  { code: 'TM', en: 'Turkmenistan', fr: 'Turkménistan', es: 'Turkmenistán', de: 'Turkmenistan', ar: 'تركمانستان' },
  { code: 'TC', en: 'Turks and Caicos Islands', fr: 'Îles Turques-et-Caïques', es: 'Islas Turcas y Caicos', de: 'Turks- und Caicosinseln', ar: 'جزر تركس وكايكوس' },
  { code: 'TV', en: 'Tuvalu', fr: 'Tuvalu', es: 'Tuvalu', de: 'Tuvalu', ar: 'توفالو' },
  { code: 'UG', en: 'Uganda', fr: 'Ouganda', es: 'Uganda', de: 'Uganda', ar: 'أوغندا' },
  { code: 'UA', en: 'Ukraine', fr: 'Ukraine', es: 'Ucrania', de: 'Ukraine', ar: 'أوكرانيا' },
  { code: 'AE', en: 'United Arab Emirates', fr: 'Émirats Arabes Unis', es: 'Emiratos Árabes Unidos', de: 'Vereinigte Arabische Emirate', ar: 'الإمارات العربية المتحدة' },
  { code: 'GB', en: 'United Kingdom', fr: 'Royaume-Uni', es: 'Reino Unido', de: 'Vereinigtes Königreich Großbritannien und Nordirland', ar: 'المملكة المتحدة' },
  { code: 'US', en: 'United States', fr: 'États-Unis', es: 'Estados Unidos de América', de: 'Vereinigte Staaten von Amerika', ar: 'الولايات المتحدة الأمريكية' },
  { code: 'UM', en: 'United States Minor Outlying Islands', fr: 'Îles mineures éloignées des États-Unis', es: 'Islas Ultramarinas Menores de Estados Unidos', de: 'United States Minor Outlying Islands', ar: 'جزر الولايات المتحدة الأمريكية النائية' },
  { code: 'UY', en: 'Uruguay', fr: 'Uruguay', es: 'Uruguay', de: 'Uruguay', ar: 'أوروغواي' },
  { code: 'UZ', en: 'Uzbekistan', fr: 'Ouzbékistan', es: 'Uzbekistán', de: 'Usbekistan', ar: 'أوزبكستان' },
  { code: 'VU', en: 'Vanuatu', fr: 'Vanuatu', es: 'Vanuatu', de: 'Vanuatu', ar: 'فانواتو' },
  { code: 'VE', en: 'Venezuela', fr: 'Venezuela', es: 'Venezuela', de: 'Venezuela', ar: 'فنزويلا' },
  { code: 'VN', en: 'Viet Nam', fr: 'Viêt Nam', es: 'Vietnam', de: 'Vietnam', ar: 'فيتنام' },
  { code: 'VG', en: 'Virgin Islands, British', fr: 'British Virgin Islands', es: 'Islas Vírgenes Británicas', de: 'Britische Jungferninseln', ar: 'جزر فيرجن البريطانية' },
  { code: 'VI', en: 'Virgin Islands, U.S.', fr: 'Îles Vierges américaines', es: 'Islas Vírgenes de los Estados Unidos de América', de: 'Amerikanische Jungferninseln', ar: 'جزر فيرجن الأمريكية' },
  { code: 'WF', en: 'Wallis and Futuna', fr: 'Wallis-et-Futuna', es: 'Wallis y Futuna', de: 'Wallis und Futuna', ar: 'واليس وفوتونا' },
  { code: 'EH', en: 'Western Sahara', fr: 'Sahara occidental', es: 'Sahara Occidental', de: 'Westsahara', ar: 'الصحراء الغربية' },
  { code: 'YE', en: 'Yemen', fr: 'Yémen', es: 'Yemen', de: 'جemen', ar: 'اليمن' },
  { code: 'ZM', en: 'Zambia', fr: 'Zambie', es: 'Zambia', de: 'Sambia', ar: 'زامبيا' },
  { code: 'ZW', en: 'Zimbabwe', fr: 'Zimbabwe', es: 'Zimbabue', de: 'Simbabwe', ar: 'زيمبابوي' }
];

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map(c => {
    const countryName = c[lang] ? c[lang] : c['en'];
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
