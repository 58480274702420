import { string } from 'prop-types';
import React from 'react';
import dealsIcon from '../../../../assets/images/dealsicon.png';
import dealsImage from '../../../../assets/images/dealsimage.png';
import { ResponsiveImage } from '../../../../components';
import MobileStore from '../../../../components/MobileStore/MobileStore';
import css from './BestDeals.module.css';

const BestDeals = (props) => {
  const { intl } = props;

  return (
    <div className={css.bestDealsWrapper}>
      <div className={css.dealsLeft}>
        <ResponsiveImage
          alt="Deals Image"
          image={dealsImage}
          variants={"custom-image"}
          className={css.dealsImage}
        />

        <ResponsiveImage
          alt="Deals Icon"
          image={dealsIcon}
          variants={"custom-image"}
          className={css.dealsIcon}
        />
      </div>
      <div className={css.dealsRight}>
        <h2>
          {intl.formatMessage({ id: "BestDeals.sendABuzzHeading" })}
        </h2>
        <p>
          {intl.formatMessage({ id: "BestDeals.utilizeOurSubHeading" })}
        </p>
        <MobileStore className={css.dealMobileStore} />
      </div>
    </div>
  )
};

BestDeals.defaultProps = {
  className: null,
  rootClassName: null,
};

BestDeals.propTypes = {
  className: string,
  rootClassName: string,
};

export default BestDeals;
