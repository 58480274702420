import { classNames } from 'primereact/utils';
import { string } from 'prop-types';
import React from 'react';
import first from '../../../../assets/images/card1.png';
import second from '../../../../assets/images/card2.png';
import third from '../../../../assets/images/card3.png';
import fourth from '../../../../assets/images/card4.png';
import fifth from '../../../../assets/images/card5.png';
import six from '../../../../assets/images/card6.png';
import { FormattedMessage } from '../../../../util/reactIntl';
import { createResourceLocatorString } from '../../../../util/routes';
import { DealType } from '../../../../util/types';
import css from './DealTypes.module.css';

const DealTypes = (props) => {
  const { intl, history, routeConfiguration } = props;
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  const dealsDetail = [
    {
      title: intl.formatMessage({ id: "DealTypes.percentageDiscount" }),
      image: first,
      key: DealType?.PERCENTAGE_DISCOUNT
    },
    {
      title: intl.formatMessage({ id: "DealTypes.bulkDiscount" }),
      image: third,
      key: DealType?.BULK_DISCOUNT
    },

    {
      title: intl.formatMessage({ id: "DealTypes.buyOneGetOneHalfOff" }, { breakLine: <br /> }),
      image: fifth,
      key: DealType?.BUY_ONE_GET_ONE_HALF_OFF
    },
    {
      title: intl.formatMessage({ id: "DealTypes.amountDiscount" }),
      image: second,
      key: DealType?.AMOUNT_DISCOUNT
    },
    {
      title: intl.formatMessage({ id: "DealTypes.buyOneGetOneFree" }, { breakLine: <br /> }),
      image: fourth,
      key: DealType?.BUY_ONE_GET_ONE_FREE
    },
    {
      title: intl.formatMessage({ id: "DealTypes.serviceBundles" }),
      image: six,
      key: DealType?.SERVICE_BUNDLES
    },
  ]


  const columnCount = 3; // Set to 3 columns
  const columns = Array.from({ length: columnCount }, (_, i) =>
    dealsDetail.filter((_, index) => index % columnCount === i)
  );

  return (
    <div className={classNames(css.dealTypesWrapper, arabicLanguage && css.arabicRowReverse)}>
      <div className={classNames(css.dealTopRow)}>
        <div className={classNames(css.dealHeading)}>
          <FormattedMessage id="CustomerHomePage.topDealTypes" />
        </div>
        <div
          className={css.viewAllLink}
          onClick={() => {
            history.push(
              createResourceLocatorString(
                'AlgoliaSearchPage',
                routeConfiguration,
                { listingType: "deals" },
                {}
              )
            );
          }}
        >
          <FormattedMessage id="CustomerHomePage.viewAll" />
        </div>
      </div>
      <div className={css.gridContainer}>
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} className={css.twoCardColumn}>
            {column.map((deal, dealIndex) => (
              <div
                key={dealIndex}
                className={classNames(css.card)}
                onClick={() => {
                  history.push(
                    createResourceLocatorString(
                      'AlgoliaSearchPage',
                      routeConfiguration,
                      { listingType: "deals" },
                      { "publicData.categoryLevel2": deal.key }
                    )
                  );
                }}
              >
                <div className={css.icon}><img src={deal.image} alt="" /></div>
                <h3>{deal.title}</h3>
                <a href="#" className={css.link}>{intl.formatMessage({ id: "DealTypes.shopNow" })}</a>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={css.signUp}>{intl.formatMessage({ id: "DealTypes.doYouOwn" })} <span>
        {intl.formatMessage({ id: "DealTypes.now" })}
      </span></div>
    </div>
  )
};

DealTypes.defaultProps = {
  className: null,
  rootClassName: null,
};

DealTypes.propTypes = {
  className: string,
  rootClassName: string,
};

export default DealTypes;
