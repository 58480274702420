import mixpanel from 'mixpanel-browser';
import React from 'react';
import { BrandingIconCard, ResponsiveImage } from '../../components';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { mixPanelEventsConstants, mixPanelEventsScreens } from '../../mixpanel';
import { convertToTitleCase } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import { DEAL_LISTING_TYPE, PRODUCT_LISTING_TYPE, SERVICE_LISTING_TYPE } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import css from './AlgoliaSearchDealCard.module.css';
import classNames from 'classnames';
import customerLogo from '../../assets/customerLogo.png';

const PERCENTAGE_DISCOUNT_TYPE = 'percentage-discount';

/**
 * Component to display a deal or service card with details fetched from Algolia search.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.hit - The deal or service data from Algolia.
 * @param {Object} props.currentUser - The current logged-in user.
 * @param {Function} props.toggleFavorite - Function to toggle the deal/service as a favorite.
 * @param {Array} props.updatedDealCategories - Updated deal categories including color and description.
 *
 * @returns {JSX.Element} The rendered component.
 */
const AlgoliaSearchDealCard = ({
  hit,
  roleName,
  currentUser,
  toggleFavorite,
  updatedDealCategories,
  history,
  routeConfiguration: routes,
}) => {
  const ownListing = hit?.author?.id === currentUser?.id?.uuid;
  // Destructure necessary fields from hit object
  const { title, price, images = [] } = hit || {};
  const {
    discount,
    listingType,
    percentage_discount,
    categoryLevel1,
    categoryLevel2,
    original_price,
  } = hit?.publicData || {};
  const routeConfiguration = useRouteConfiguration();
  // Check if the deal type is a percentage discount
  const isPercentageDiscount =
    categoryLevel2 === PERCENTAGE_DISCOUNT_TYPE || listingType === PERCENTAGE_DISCOUNT_TYPE;
  const userType = currentUser?.attributes?.profile?.publicData?.userType || '';
  // Determine the correct wishlist type based on listingType
  // Determine the correct wishlist type based on listingType
  const wishlistTypeKey =
    listingType === SERVICE_LISTING_TYPE
      ? 'wishlist_service_ids'
      : listingType === DEAL_LISTING_TYPE
        ? 'wishlist_deal_ids'
        : listingType === PRODUCT_LISTING_TYPE
          ? 'wishlist_product_ids'
          : null;

  const wishlist = currentUser?.attributes?.profile?.publicData?.[wishlistTypeKey] || [];

  // Check if this listing is already marked as a favorite
  const isFavorite = wishlist.includes(hit?.objectID);

  // Generate slug for the listing page URL
  const listingId = hit?.objectID;
  const listingSlug = createSlug(title);

  // Find the corresponding subcategory and get its color
  const subcategory =
    updatedDealCategories
      ?.find(cat => cat.subcategories.some(sub => sub.id === categoryLevel2))
      ?.subcategories.find(sub => sub.id === categoryLevel2) || {};
  const subcategoryColor = subcategory.color || '#000'; // Default to black if no color is found

  /**
   * Handles the click event for adding/removing a listing to/from favorites.
   */
  const handleFavoriteClick = () => {
    if (currentUser === null) {
      history.push(createResourceLocatorString('LoginPage', routes, {}, {}));
    } else {
      toggleFavorite(hit?.objectID, listingType, currentUser, hit?.author?.id, title, images);
    }
  };
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  return (
    <div className={classNames(css.dealCardWrapper, arabicLanguage && css.arabicDirection)}>
      <div>
        {/* <NamedLink name="ListingPage" > */}
        <div className={css.root}>
          <div className={css.jobCard}>
            <div className={css.textContent}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'ListingPage',
                    routeConfiguration,
                    { id: listingId, slug: listingSlug },
                    {}
                  )
                );
                if (currentUser && userType == 'customer') {
                  mixpanel.track(mixPanelEventsConstants.OPEN_DEAL, {
                    screenName: mixPanelEventsScreens.ALOGLIA_SEARCH_PAGE,
                    userID: currentUser?.id.uuid,
                    userType: 'Customer',
                  });
                }
              }}
            >
              <div className={css.imageWrapper}>
                {images.length && images[0] ? <ResponsiveImage
                  alt={title}
                  image={images[0]}
                  variants={'custom-image'}
                  width={'100%'}
                  height={300}
                /> :
                  <ResponsiveImage
                      alt="card image"
                      localImageSrc={customerLogo}
                      className={css.cardImage}
                      variants={"custom-image"}
                    />}
                {isPercentageDiscount ? (
                  <div className={css.percentage} style={{ backgroundColor: subcategoryColor }}>
                    -{percentage_discount ? percentage_discount : discount} %
                  </div>
                ) : (
                  <div className={css.percentage} style={{ backgroundColor: subcategoryColor }}>
                    {original_price
                      ? (original_price - price?.amount / 100).toFixed(2) + price?.currency
                      : `0 ${price?.currency}`}
                  </div>
                )}

              </div>
              <h2 className={css.dealTitle}>{title}</h2>
              <div className={css.dealType} style={{ backgroundColor: subcategoryColor }}>
                {categoryLevel2
                  ? convertToTitleCase(categoryLevel2)
                  : convertToTitleCase(categoryLevel1)}
              </div>
              <div className={css.priceWrapper}>
                <span className={css.actualPrice}>
                  {original_price ? `${price?.currency} ${original_price}` : null}
                </span>
                <span className={css.dealPrice}>
                  {price?.amount ? price?.amount / 100 + price?.currency : `0 ${price?.currency}`}
                </span>
              </div>
            </div>
            {!ownListing && <span
              className={isFavorite ? css.likeIconWrapper : css.wishListIcon}
              onClick={e => {
                e.preventDefault();
                handleFavoriteClick();
              }}
            >
              <BrandingIconCard type="heart" />
            </span>}
          </div>
        </div>
        {/* </NamedLink> */}
      </div>
    </div>
  );
};

export default AlgoliaSearchDealCard;
