import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import MerchantHomePage from './MerchantHomePage/MerchantHomePage';
import CustomerHomePage from './CustomerHomePage/CustomerHomePage';
import DashboardPage from '../DashboardPage/DashboardPage';
import { transformCategories } from '../../util/data';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error,categories,dealCategories } = props;
  // console.log('dealCategories', dealCategories)
  const organizedCategories = transformCategories(categories);
  const organizedDealCategories = transformCategories(dealCategories);;
  // const { currentUser: { attributes: { profile: { publicData: { userType } } } } } = useSelector(state => state?.user)
  const { currentUser } = useSelector(state => state?.user);

  const userType = currentUser?.attributes?.profile?.publicData?.userType;

  const isAuthenticated = currentUser?.attributes?.profile?.publicData?.userType !== undefined;

  if (isAuthenticated) {
    if (userType === 'customer') {
      return <CustomerHomePage pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data} organizedCategories={organizedCategories}
       organizedDealCategories={organizedDealCategories}
       />;
    } else if (userType === 'provider') {
      return <DashboardPage pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data} />;
    }
  }

  // Default case when user is not authenticated or userType is undefined
  return <CustomerHomePage pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data} organizedCategories={organizedCategories}
   organizedDealCategories={organizedDealCategories}
   />;
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { categoriesSuccess: categories } = state.LandingPage;
  const {dealsCategoriesSuccess:dealCategories} = state.LandingPage;
  return { pageAssetsData, inProgress, error, categories,dealCategories };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
