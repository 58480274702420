import pick from 'lodash/pick';
import moment from 'moment';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import {
  transitions as bookingTransition,
  transitions,
} from '../../transactions/transactionProcessBooking';
import { transitions as dealTransitions } from '../../transactions/transactionProcessDeal';
import { transitions as purchaseTransition } from '../../transactions/transactionProcessPurchase';
import { initiatePrivileged, onCreateCharge, onGeneratePaymetStatus, transitionPrivileged } from '../../util/api';
// import { initiatePrivileged, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import { DEAL_LISTING_TYPE, SERVICE_LISTING_TYPE } from '../../util/types';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const INITIATE_INQUIRY_REQUEST = 'app/CheckoutPage/INITIATE_INQUIRY_REQUEST';
export const INITIATE_INQUIRY_SUCCESS = 'app/CheckoutPage/INITIATE_INQUIRY_SUCCESS';
export const INITIATE_INQUIRY_ERROR = 'app/CheckoutPage/INITIATE_INQUIRY_ERROR';

// Action Types for Charge Request
export const INITIATE_CHARGE_REQUEST = 'app/CheckoutPage/INITIATE_CHARGE_REQUEST';
export const INITIATE_CHARGE_SUCCESS = 'app/CheckoutPage/INITIATE_CHARGE_SUCCESS';
export const INITIATE_CHARGE_ERROR = 'app/CheckoutPage/INITIATE_CHARGE_ERROR';

export const UPDATE_ORDER_DATA_SUCCESS = 'app/CheckoutPage/UPDATE_ORDER_DATA_SUCCESS';

export const PAYMENT_STATUS_REQUEST = 'app/CheckoutPage/PAYMENT_STATUS_REQUEST';
export const PAYMENT_STATUS_SUCCESS = 'app/CheckoutPage/PAYMENT_STATUS_SUCCESS';
export const PAYMENT_STATUS_ERROR = 'app/CheckoutPage/PAYMENT_STATUS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  isClockInSync: false,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  initiateInquiryInProgress: false,
  initiateInquiryError: null,
  cartListings: null,
  cartAuthorId: null,

  initiateChargeInProgress: false,
  initiateChargeError: null,
  chargeData: null, // This will hold the response data if charge is successful
  loading: false,
  data: null,
  error: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS: {
      // Check that the local devices clock is within a minute from the server
      const lastTransitionedAt = payload.transaction?.attributes?.lastTransitionedAt;
      const localTime = new Date();
      const minute = 60000;
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
        isClockInSync: Math.abs(lastTransitionedAt?.getTime() - localTime.getTime()) < minute,
      };
    }
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case INITIATE_INQUIRY_REQUEST:
      return { ...state, initiateInquiryInProgress: true, initiateInquiryError: null };
    case INITIATE_INQUIRY_SUCCESS:
      return { ...state, initiateInquiryInProgress: false };
    case INITIATE_INQUIRY_ERROR:
      return { ...state, initiateInquiryInProgress: false, initiateInquiryError: payload };

    case INITIATE_CHARGE_REQUEST:
      return {
        ...state,
        initiateChargeInProgress: true,
        initiateChargeError: null,
      };

    case INITIATE_CHARGE_SUCCESS:
      return {
        ...state,
        initiateChargeInProgress: false,
        chargeData: payload,
      };

    case INITIATE_CHARGE_ERROR:
      return {
        ...state,
        initiateChargeInProgress: false,
        initiateChargeError: payload,
      };

    case UPDATE_ORDER_DATA_SUCCESS:
      return {
        ...state,
        orderData: { ...payload.orderData }
      }
    case PAYMENT_STATUS_REQUEST:
          return {
            ...state,
            loading: true,
            error: null, // Reset error when making a new request
          };
        case PAYMENT_STATUS_SUCCESS:
          return {
            ...state,
            loading: false,
            data: action.payload,
          };
        case PAYMENT_STATUS_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };  
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const initiateChargeRequest = () => ({
  type: INITIATE_CHARGE_REQUEST,
});

export const initiateChargeSuccess = chargeData => ({
  type: INITIATE_CHARGE_SUCCESS,
  payload: chargeData,
});

export const initiateChargeError = error => ({
  type: INITIATE_CHARGE_ERROR,
  error,
});

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const initiateInquiryRequest = () => ({ type: INITIATE_INQUIRY_REQUEST });
export const initiateInquirySuccess = () => ({ type: INITIATE_INQUIRY_SUCCESS });
export const initiateInquiryError = e => ({
  type: INITIATE_INQUIRY_ERROR,
  error: true,
  payload: e,
});

export const updateOrderData = payload => ({
  type: UPDATE_ORDER_DATA_SUCCESS,
  payload,
});

export const paymentStatusRequest = () => ({
  type: PAYMENT_STATUS_REQUEST,
});

export const paymentStatusSuccess = data => ({
  type: PAYMENT_STATUS_SUCCESS,
  payload: data,
});

export const paymentStatusError = error => ({
  type: PAYMENT_STATUS_ERROR,
  payload: error,
});

/* ================ Thunks ================ */

export const initiateCharge = chargeDetails => async dispatch => {
  dispatch(initiateChargeRequest());
  try {
    const response = await onCreateCharge(chargeDetails);
    await dispatch(initiateChargeSuccess(response));

    if (response?.transaction?.url) {
      window.open(response.transaction.url, '_blank');
    }

    return response;
  } catch (error) {
    dispatch(initiateChargeError(error.response ? error.response.data : error.message));
  }
};

export const checkPaymentStatus = (params, search, config) => async (dispatch, getState) => {

  const { paymentId } = params || {};
  dispatch(paymentStatusRequest());
  return onGeneratePaymetStatus({ paymentId: paymentId })
    .then(res => {
      const { id: tapCustomerId } = res?.customer || {};
      const { id: paymentAgreementId, contract } = res?.payment_agreement || {};
      const cardId = contract?.id;

      dispatch(fetchCurrentUser());
      const currentUser = getState().user?.currentUser || {};
      const oldCards = currentUser?.attributes?.profile?.protectedData?.cards || [];

      const newCard = {
        paymentAgreementId,
        cardId,
      }

      const newCardMaybe = oldCards.find(card => card.cardId === cardId) ? [] : [newCard];

      const updatedCards = [
        ...oldCards,
        ...newCardMaybe,
      ];

      dispatch(updateProfile({
        publicData: { customerId: tapCustomerId },
        protectedData: { cards: updatedCards }
      }));
      dispatch(paymentStatusSuccess(res));
    })
    .catch(err => {
      dispatch(paymentStatusError('Error loading payment status data!', err));
    });
};



/**
 * INITIATE ORDER
 */
export const initiateOrder = ({
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition,
}) => (dispatch, getState, sdk) => {
  try {
    // If we already have a transaction ID, we should transition, not
    // initiate.
    const isTransition = !!transactionId;

    const { deliveryMethod, quantity, cart, bookingDates, ...otherOrderParams } = orderParams;

    const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

    const bookingParamsMaybe = bookingDates || {};

    // if (bookingDates) {
    //   bookingParamsMaybe = {
    //     ...bookingDates,
    //     bookingEnd: addBuffer(bookingDates?.bookingEnd),
    //     bookingDisplayEnd: bookingDates?.bookingEnd,
    //     bookingDisplayStart: bookingDates?.bookingStart,
    //   };
    // }

    // Parameters only for client app's server
    const orderData = deliveryMethod ? { cart, deliveryMethod } : { cart };

    // Parameters for Marketplace API
    const transitionParams = {
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...otherOrderParams,
    };

    const bodyParams = isTransition
      ? {
        id: transactionId,
        transition: transitionName,
        params: transitionParams,
      }
      : {
        processAlias,
        transition: transitionName,
        params: transitionParams,
      };
    const queryParams = {
      include: ['booking', 'provider', 'listing'],
      expand: true,
    };

    const handleSucces = response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    };

    const handleError = e => {
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        ...quantityMaybe,
        ...bookingParamsMaybe,
        ...orderData,
      });
      throw e;
    };
    if (isTransition && isPrivilegedTransition) {
      // transition privileged
      return transitionPrivileged({
        isSpeculative: false,
        orderData,
        bodyParams,
        queryParams,
      })
        .then(handleSucces)
        .catch(handleError);
    } else if (isTransition) {
      // transition non-privileged
      return sdk.transactions
        .transition(bodyParams, queryParams)
        .then(handleSucces)
        .catch(handleError);
    } else if (isPrivilegedTransition) {
      // initiate privileged
      return initiatePrivileged({
        isSpeculative: false,
        orderData,
        bodyParams,
        queryParams,
      })
        .then(handleSucces)
        .catch(handleError);
    } else {
      // initiate non-privileged
      return sdk.transactions
        .initiate(bodyParams, queryParams)
        .then(handleSucces)
        .catch(handleError);
    }
  } catch (error) { }
};

export const confirmPayment = params => (dispatch, getState, sdk) => {
  const { transactionId, transitionName, transitionParams = {} } = params;
  dispatch(confirmPaymentRequest());
  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };
  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  return sdk.transactions
    .transition(bodyParams, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(confirmPaymentSuccess(order?.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId?.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

/**
 * creating child transactions for both service/product
 */
const createChildTransactions = async (orderParams, sdk, isBookingProcess, isDealProcess) => {
  //remove parent listing from cart
  const { attributes, id, listing } = orderParams;
  const parentTransactionId = id.uuid;
  const { cart } = attributes?.protectedData;
  cart?.deliveryMethod ? delete cart?.deliveryMethod : null;
  if (isBookingProcess || isDealProcess) {
    for (const [key, value] of Object.entries(cart)) {
      if (value?.staffId === listing?.id?.uuid) {
        delete cart[key];
        break;
      }
    }
  } else {
    delete cart[listing?.id?.uuid];
  }
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  const childTransaction = Object.keys(cart).map(async item => {
    // Check if the deal has already been created as a parent transaction
    // If yes thn we won't create a new transaction for it
    const isDealIsParent = item === listing?.id?.uuid;

    const quantity = cart[item]?.count;
    const isService = cart[item]?.type === SERVICE_LISTING_TYPE;
    const isDeal = cart[item]?.type === DEAL_LISTING_TYPE;

    const processAlias = isService ? 'default-booking/release-1' : 'default-purchase/release-1';
    const transitionName = transitions.CHILD_REQUEST_PAYMENT;

    const handleSuccess = response => {
      return response.data.data.id.uuid; // Ensure this returns the expected ID
    };
    const handleError = error => {
      console.log('child initiate error', JSON.stringify(error));
    };

    if (isDeal) {
      const initialDealParams = {
        processAlias: 'default-deal-booking/release-1',
        transition: 'transition/request-payment',
        params: {
          listingId: item,
          bookingStart: moment()
            .startOf('day')
            .toISOString(),
          bookingEnd: moment()
            .add(1, 'day')
            .startOf('day')
            .toISOString(),
          protectedData: {
            unitType: 'day',
            deliveryMethod: 'shipping',
            parentTransactionId: parentTransactionId,
          },
        },
      };
      // Only create a new deal if its not already been created
      let dealParentTransactionId = parentTransactionId;
      if (!isDealIsParent) {
        const initialDealParams = {
          processAlias: 'default-deal-booking/release-1',
          transition: 'transition/request-payment',
          params: {
            listingId: item,
            bookingStart: moment()
              .startOf('day')
              .toISOString(),
            bookingEnd: moment()
              .add(1, 'day')
              .startOf('day')
              .toISOString(),
            protectedData: {
              unitType: 'day',
              deliveryMethod: 'shipping',
              parentTransactionId: parentTransactionId,
            },
          },
        };

        // Await the initial deal creation
        const initialDealResponse = await initiatePrivileged({
          isSpeculative: false,
          bodyParams: initialDealParams,
          queryParams,
        })
          .then(handleSuccess)
          .catch(handleError);

        // Extract the new parent transaction ID from the response
        dealParentTransactionId = initialDealResponse;
      }

      // Collect all promises for the current item
      const dealPromises = cart[item]?.dealListingIds?.map(async deal => {
        const isServiceDeal = deal?.type === SERVICE_LISTING_TYPE;

        let dealParams = {};
        if (isServiceDeal) {
          dealParams = {
            processAlias: 'default-deal-booking/release-1',
            transition: 'transition/child-service-request-payment',
            params: {
              listingId: deal?.staffId,
              bookingStart: new Date(Number(deal?.bookingStart)),
              bookingEnd: new Date(Number(deal?.bookingEnd)),
              protectedData: {
                serviceId: deal?.id,
                unitType: 'hour',
                deliveryMethod: 'pickup',
                parentTransactionId: dealParentTransactionId,
              },
            },
          };
        } else {
          dealParams = {
            processAlias: 'default-deal-booking/release-1',
            transition: 'transition/child-product-request-payment',
            params: {
              listingId: deal?.id,
              stockReservationQuantity: quantity,
              protectedData: {
                parentTransactionId: dealParentTransactionId,
              },
            },
          };
        }

        return initiatePrivileged({
          isSpeculative: false,
          bodyParams: dealParams,
          queryParams,
        })
          .then(handleSuccess)
          .catch(handleError);
      });

      // Wait for all child item creation promises to resolve
      return Promise.all(dealPromises)
        .then(results => results.flat())
        .then(dealItemsTransactionIds => {
          return Promise.all(dealItemsTransactionIds).then(childIds => {
            const formattedChildId = childIds.flat();
            // Update child transaction ids
            const parentBodyParams = {
              id: dealParentTransactionId,
              transition: 'transition/update-child-transactions',
              params: {
                protectedData: {
                  dealChildTransactions: formattedChildId,
                },
              },
            };
            return sdk.transactions
              .transition(parentBodyParams, {
                expand: true,
              })
              .then(res => {
                // const entities = denormalisedResponseEntities(res);
                // const order = entities[0];
                // return order;
                return dealParentTransactionId;
              });
          });
        });
    } else {
      const bookingParams = {
        listingId: cart[item]?.staffId,
        bookingStart: new Date(Number(cart[item]?.bookingStart)),
        bookingEnd: new Date(Number(cart[item]?.bookingEnd)),
        protectedData: {
          serviceId: item,
          unitType: 'hour',
          deliveryMethod: 'pickup',
          parentTransactionId: parentTransactionId,
        },
      };
      const purchaseParams = {
        listingId: item,
        stockReservationQuantity: quantity,
        protectedData: {
          parentTransactionId: parentTransactionId,
        },
      };
      const bodyParams = {
        processAlias: processAlias,
        transition: transitionName,
        params: {
          ...(isService ? bookingParams : purchaseParams),
        },
      };

      return initiatePrivileged({
        isSpeculative: false,
        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    }
  });

  return Promise.all(childTransaction).then(childIds => {
    const formattedChildId = childIds.flat();
    // Update child transaction ids
    const parentBodyParams = {
      id: parentTransactionId,
      transition: isDealProcess
        ? 'transition/child-confirm-payment'
        : isBookingProcess
          ? bookingTransition.CONFIRM_PAYMENT
          : purchaseTransition.UPDATE_CHILD_TRANSCTIONS,
      params: {
        protectedData: {
          childTransactions: formattedChildId,
        },
      },
    };
    return sdk.transactions
      .transition(parentBodyParams, {
        expand: true,
      })
      .then(res => {
        const entities = denormalisedResponseEntities(res);
        const order = entities[0];
        return order;
      });
  });
};

/**
 * intiate child and update parent.
 * in this step we can have both products and service
 * initate child service/products. if parent is product then update the product instead of creating
 */
export const initateChildUpdateParentTransaction = params => async (dispatch, getState, sdk) => {
  try {
    const { orderParams, isBookingProcess, isDealProcess } = params;
    const response = await createChildTransactions(
      orderParams,
      sdk,
      isBookingProcess,
      isDealProcess
    );
    return response;
  } catch (error) { }
};

// //// Create Individual  transactions
export const InitiateIndividualTransactions = params => async (dispatch, getState, sdk) => {
  try {
    const { orderParams } = params;
    const response = await createIndividualTransactions(orderParams, sdk);
    return response;
  } catch (error) { }
};

// Create one transaction for each Cart Item
const createIndividualTransactions = async (orderParams, sdk) => {
  const { cart, chargeId } = orderParams?.protectedData || {};
  // Delete deliveryMethod key from cart
  cart?.deliveryMethod ? delete cart?.deliveryMethod : null;

  // Query Params
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  // Create Cart Items Transactions
  const cartItemsTransactions = Object.keys(cart).map(async item => {
    const quantity = cart[item]?.count;
    const isService = cart[item]?.type === SERVICE_LISTING_TYPE;
    const isDeal = cart[item]?.type === DEAL_LISTING_TYPE;

    const processAlias = isService
      ? 'default-booking/release-1'
      : isDeal
        ? 'default-deal-booking/release-1'
        : 'default-purchase/release-1';

    const transitionName = transitions.REQUEST_PAYMENT;

    // Success and Error Handlers
    const handleSuccess = response => {
      return response?.data?.data?.id?.uuid;
    };
    const handleError = error => {
      console.log('initiate transaction error', JSON.stringify(error));
    };

    ////// If current Cart Item is a Deal then first create the Deal transaction
    ////// and then its deal items transactions
    if (isDeal) {
      let dealParentTransactionId = null;

      const initialDealParams = {
        processAlias: processAlias,
        transition: transitionName,
        params: {
          ...orderParams,
          listingId: item,
          bookingStart: moment()
            .startOf('day')
            .toISOString(),
          bookingEnd: moment()
            .add(1, 'day')
            .startOf('day')
            .toISOString(),
          protectedData: {
            unitType: 'day',
            deliveryMethod: 'shipping',
            // chargeId: chargeId,
          },
        },
      };

      // Await the initial deal creation
      const initialDealResponse = await initiatePrivileged({
        isSpeculative: false,
        orderData: { cart },
        bodyParams: initialDealParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);

      // Extract the Deal transaction ID from the response
      dealParentTransactionId = initialDealResponse;

      // Now its time to create child transactions for Deal Items
      const dealPromises = cart[item]?.dealListingIds?.map(async dealItem => {
        const isServiceDeal = dealItem?.type === SERVICE_LISTING_TYPE;

        let dealItemParams = {};
        if (isServiceDeal) {
          dealItemParams = {
            processAlias: processAlias,
            transition: dealTransitions?.CHILD_SERVICE_REQUEST_PAYMENT,
            params: {
              ...orderParams,
              listingId: dealItem?.staffId,
              bookingStart: new Date(Number(dealItem?.bookingStart)),
              bookingEnd: new Date(Number(dealItem?.bookingEnd)),
              protectedData: {
                serviceId: dealItem?.id,
                unitType: 'hour',
                deliveryMethod: 'pickup',
                parentTransactionId: dealParentTransactionId,
              },
            },
          };
        } else {
          dealItemParams = {
            processAlias: processAlias,
            transition: dealTransitions?.CHILD_PRODUCT_REQUEST_PAYMENT,
            params: {
              ...orderParams,
              listingId: dealItem?.id,
              stockReservationQuantity: quantity,
              protectedData: {
                parentTransactionId: dealParentTransactionId,
              },
            },
          };
        }

        return initiatePrivileged({
          isSpeculative: false,
          orderData: { cart },
          bodyParams: dealItemParams,
          queryParams,
        })
          .then(handleSuccess)
          .catch(handleError);
      });

      // Wait for all deal child tx creation promises to resolve
      return Promise.all(dealPromises)
        .then(results => results.flat())
        .then(dealItemsTransactionIds => {
          return Promise.all(dealItemsTransactionIds).then(childIds => {
            // // Store dealItems transactionsIds in its Deal
            const parentBodyParams = {
              id: dealParentTransactionId,
              transition: dealTransitions?.UPDATE_CHILD_TRANSACTIONS,
              params: {
                protectedData: {
                  dealChildTransactions: dealItemsTransactionIds,
                },
              },
            };
            return sdk.transactions
              .transition(parentBodyParams, {
                expand: true,
              })
              .then(res => dealParentTransactionId);
          });
        });
    } else {
      const bookingParams = {
        ...orderParams,
        listingId: cart[item]?.staffId,
        bookingStart: new Date(Number(cart[item]?.bookingStart)),
        bookingEnd: new Date(Number(cart[item]?.bookingEnd)),
        protectedData: {
          serviceId: item,
          unitType: 'hour',
          deliveryMethod: 'pickup',
          // chargeId: chargeId,
        },
      };
      const purchaseParams = {
        ...orderParams,
        listingId: item,
        stockReservationQuantity: quantity,
        protectedData: {
          // chargeId: chargeId,
        },
      };
      const bodyParams = {
        processAlias: processAlias,
        transition: transitionName,
        params: {
          ...(isService ? bookingParams : purchaseParams),
        },
      };

      return initiatePrivileged({
        isSpeculative: false,
        orderData: { cart },
        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    }
  });

  return Promise.all(cartItemsTransactions).then(cartItemsTxs => cartItemsTxs);
};

// Create one transaction for each Cart Item
// const createIndividualTransactions = async (orderParams, sdk) => {
//   const cart = orderParams?.protectedData?.cart || {};
//   cart?.deliveryMethod ? delete cart?.deliveryMethod : null;

//   const chargeId = orderParams?.protectedData?.chargeId;

//   const queryParams = {
//     include: ['booking', 'provider'],
//     expand: true,
//   };

//   const cartItemsTransactions = Object.keys(cart).map(async item => {
//     const quantity = cart[item]?.count;
//     const isService = cart[item]?.type === SERVICE_LISTING_TYPE;
//     const isDeal = cart[item]?.type === DEAL_LISTING_TYPE;

//     const processAlias = isService
//       ? 'default-booking/release-1'
//       : isDeal
//         ? 'default-deal-booking/release-1'
//         : 'default-purchase/release-1';

//     const transitionName = transitions.REQUEST_PAYMENT;

//     const handleSuccess = response => {
//       return response?.data?.data?.id?.uuid;
//     };
//     const handleError = error => {
//       console.log('initiate transaction error', JSON.stringify(error));
//     };

//     ////// If current Cart Item is a Deal then first create the Deal transaction
//     ////// and then its deal items transactions
//     if (isDeal) {
//       let dealParentTransactionId = null;

//       const initialDealParams = {
//         processAlias: processAlias,
//         transition: transitionName,
//         params: {
//           ...orderParams,
//           listingId: item,
//           bookingStart: moment()
//             .startOf('day')
//             .toISOString(),
//           bookingEnd: moment()
//             .add(1, 'day')
//             .startOf('day')
//             .toISOString(),
//           protectedData: {
//             unitType: 'day',
//             deliveryMethod: 'shipping',
//             chargeId: chargeId,
//           },
//         },
//       };

//       // Await the initial deal creation
//       const initialDealResponse = await initiatePrivileged({
//         isSpeculative: false,
//         orderData: { cart },
//         bodyParams: initialDealParams,
//         queryParams,
//       })
//         .then(handleSuccess)
//         .catch(handleError);

//       // Extract the parent transaction ID from the response
//       dealParentTransactionId = initialDealResponse;

//       // Now its time to create child transactions for Deal Items
//       const dealPromises = cart[item]?.dealListingIds?.map(async dealItem => {
//         const isServiceDeal = dealItem?.type === SERVICE_LISTING_TYPE;

//         let dealItemParams = {};
//         if (isServiceDeal) {
//           dealItemParams = {
//             processAlias: processAlias,
//             transition: dealTransitions?.CHILD_SERVICE_REQUEST_PAYMENT,
//             params: {
//               ...orderParams,
//               listingId: dealItem?.staffId,
//               bookingStart: new Date(Number(dealItem?.bookingStart)),
//               bookingEnd: new Date(Number(dealItem?.bookingEnd)),
//               protectedData: {
//                 serviceId: dealItem?.id,
//                 unitType: 'hour',
//                 deliveryMethod: 'pickup',
//                 parentTransactionId: dealParentTransactionId,
//               },
//             },
//           };
//         } else {
//           dealItemParams = {
//             processAlias: processAlias,
//             transition: dealTransitions?.CHILD_PRODUCT_REQUEST_PAYMENT,
//             params: {
//               ...orderParams,
//               listingId: dealItem?.id,
//               stockReservationQuantity: quantity,
//               protectedData: {
//                 parentTransactionId: dealParentTransactionId,
//               },
//             },
//           };
//         }

//         return initiatePrivileged({
//           isSpeculative: false,
//           orderData: { cart },
//           bodyParams: dealItemParams,
//           queryParams,
//         })
//           .then(handleSuccess)
//           .catch(handleError);
//       });

//       // Wait for all child item creation promises to resolve
//       return Promise.all(dealPromises)
//         .then(results => results.flat())
//         .then(dealItemsTransactionIds => {
//           return Promise.all(dealItemsTransactionIds).then(childIds => {
//             // // Store dealItems transactionsIds in its Deal
//             const parentBodyParams = {
//               id: dealParentTransactionId,
//               transition: dealTransitions?.UPDATE_CHILD_TRANSACTIONS,
//               params: {
//                 protectedData: {
//                   dealChildTransactions: dealItemsTransactionIds,
//                 },
//               },
//             };
//             return sdk.transactions
//               .transition(parentBodyParams, {
//                 expand: true,
//               })
//               .then(res => {
//                 // Transition dealItems transaction to Confirm
//                 return Promise.all(
//                   dealItemsTransactionIds.map(dealItem => {
//                     return sdk.transactions.transition({
//                       id: dealItem,
//                       transition: dealTransitions?.DEAL_CHILD_CONFIRM_PAYMENT,
//                       params: {},
//                     });
//                   })
//                 ).then(() => {
//                   return dealParentTransactionId;
//                 });
//               });
//           });
//         });
//     } else {
//       const bookingParams = {
//         ...orderParams,
//         listingId: cart[item]?.staffId,
//         bookingStart: new Date(Number(cart[item]?.bookingStart)),
//         bookingEnd: new Date(Number(cart[item]?.bookingEnd)),
//         protectedData: {
//           serviceId: item,
//           unitType: 'hour',
//           deliveryMethod: 'pickup',
//           chargeId: chargeId,
//         },
//       };
//       const purchaseParams = {
//         ...orderParams,
//         listingId: item,
//         stockReservationQuantity: quantity,
//         protectedData: {
//           chargeId: chargeId,
//         },
//       };
//       const bodyParams = {
//         processAlias: processAlias,
//         transition: transitionName,
//         params: {
//           ...(isService ? bookingParams : purchaseParams),
//         },
//       };

//       return initiatePrivileged({
//         isSpeculative: false,
//         orderData: { cart },
//         bodyParams,
//         queryParams,
//       })
//         .then(handleSuccess)
//         .catch(handleError);
//     }
//   });

//   return Promise.all(cartItemsTransactions).then(cartItemsTxs => {
//     cartItemsTxs.map(cartItemTx => {
//       const bodyParams = {
//         id: cartItemTx,
//         transition: bookingTransition?.CHILD_CONFIRM_PAYMENT,
//         params: {
//           protectedData: {
//             // childTransactions: formattedChildId,
//           },
//         },
//       };
//       return sdk.transactions
//         .transition(bodyParams, {
//           expand: true,
//         })
//         .then(res => {
//           console.log('res', res);
//           const entities = denormalisedResponseEntities(res);
//           const order = entities[0];
//           return order;
//         });
//     });

//     return cartItemsTxs;
//   });
// };

/**
 * initiate child transactions
 */
export const initateChildTransactions = ({
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  // isPrivilegedTransition,
}) => (dispatch, getState, sdk) => {
  try {
    const { attributes, id, relationships } = orderParams;
    const parentTransactionId = id.uuid;
    const { cart, deliveryMethod } = attributes?.protectedData;
    const { listing } = relationships || {};

    //remove parent id
    delete cart[listing?.data?.id?.uuid];
    cart?.deliveryMethod ? delete cart?.deliveryMethod : null;

    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };

    const chidTransaction = Object.keys(cart).map(item => {
      const count = cart[item].count;

      const bodyParams = {
        processAlias: processAlias,
        transition: transitionName,
        params: {
          listingId: item,
          stockReservationQuantity: count,
          protectedData: {
            parentTransactionId: parentTransactionId,
          },
        },
      };

      const handleSuccess = response => {
        return response?.data?.data?.id?.uuid;
      };

      const handleError = error => {
        console.log('error¯¯¯¯¯s̄', error);
      };

      return initiatePrivileged({
        isSpeculative: false,

        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    });

    return Promise.all(chidTransaction).then(childIds => {
      const parentProtectedData = {
        childTransactions: childIds,
      };

      // Update child transaction ids
      const parentBodyParams = {
        id: parentTransactionId,
        transition: transitions.UPDATE_CHILD_TRANSACTIONS,
        params: {
          protectedData: parentProtectedData,
        },
      };

      return sdk.transactions
        .transition(parentBodyParams, {
          expand: true,
        })
        .then(res => {
          const entities = denormalisedResponseEntities(res);
          const order = entities[0];
          return order;
        });
    });
  } catch (error) { }
};

/**
 * update child transactions
 */
export const updateChildTransactions = ({ paymentId, txIds = [] }) => async (
  dispatch,
  getState,
  sdk
) => {

  const response = await Promise.all(
    txIds.map(txId => {
      const bodyParams = {
        id: new UUID(txId),
        // TODO : import transition name
        transition: 'transition/child-confirm-payment',
        params: {
          protectedData: {
            paymentId,
          }
        },
      };
      return sdk.transactions
        .transition(bodyParams, { expand: true })
        .then(res => {
          const entities = denormalisedResponseEntities(res);

          const entity = entities?.[0] || {};
          const { dealChildTransactions = [] } = entity?.attributes?.protectedData || {};

          if (dealChildTransactions.length) {
            const confirmDealItems = dealChildTransactions.map(dealChildTx => {
              const bodyParams = {
                id: dealChildTx,
                // TODO : import transition name
                transition: 'transition/deal-child-confirm-payment',
                params: {
                  protectedData: {
                    paymentId,
                  }
                },
              };

              sdk.transactions
                .transition(bodyParams, { expand: true })
            });
          }
          return entity;
        })
        .catch(e => {
          console.error('Error in confirm payment', e.message);
        });
    })
  );

  return Array.isArray(response) ? response.map(tx => tx.id.uuid) : [];
};

/**
 * initiate child bookings
 */
export const initiateChildBookings = ({
  orderParams,
  childTransitionName,
  parentTransitionName,
  processAlias,
}) => (dispatch, getState, sdk) => {
  try {
    const { attributes, id, relationships } = orderParams;
    const parentTransactionId = id.uuid;
    const { cart, deliveryMethod } = attributes?.protectedData;

    const firstKey = Object.keys(cart)[0];
    delete cart[firstKey];

    cart?.deliveryMethod ? delete cart?.deliveryMethod : null;

    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };
    const chidTransaction = Object.keys(cart).map(item => {
      const bodyParams = {
        processAlias: processAlias,
        transition: childTransitionName,
        params: {
          listingId: cart[item]?.staffId,
          bookingStart: new Date(Number(cart[item]?.bookingStart)),
          bookingEnd: new Date(Number(cart[item]?.bookingEnd)),
          protectedData: {
            serviceId: item,
            unitType: 'hour',
            deliveryMethod: 'pickup',
            parentTransactionId: parentTransactionId,
          },
        },
      };
      const handleSuccess = response => {
        return response?.data?.data?.id?.uuid;
      };

      const handleError = error => {
        console.log('error?????', JSON.stringify(error));
      };

      return initiatePrivileged({
        isSpeculative: false,
        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    });
    return Promise.all(chidTransaction).then(childIds => {
      // Update child transaction ids
      const parentBodyParams = {
        id: parentTransactionId,
        transition: parentTransitionName,
        params: {
          protectedData: {
            childTransactions: childIds,
          },
        },
      };

      return sdk.transactions
        .transition(parentBodyParams, {
          expand: true,
        })
        .then(res => {
          const entities = denormalisedResponseEntities(res);
          const order = entities[0];
          return order;
        });
    });
  } catch (error) { }
};

/**
 * update child bookings
 */
export const updateChildBookings = ({ orderParams, transitionName, childIds }) => (
  dispatch,
  getState,
  sdk
) => {
  try {
    return Promise.all(
      childIds.map(item => {
        const childBodyParams = {
          id: item,
          transition: transitionName,
          params: {},
        };
        return sdk.transactions.transition(childBodyParams, {
          expand: true,
        });
      })
    ).then(res => {
      return orderParams;
    });
  } catch (error) { }
};

// replace this thunk later in cart slice
export const clearAuthorCart = () => (dispatch, getState, sdk) => {
  try {
    const state = getState();
    const currentAuthor = state?.CartPage?.currentAuthor;
    const cart = state?.CartPage?.cart;

    const newCart = {
      ...cart,
    };

    delete newCart[currentAuthor?.id?.uuid];

    return sdk.currentUser.updateProfile({
      privateData: {
        cart: newCart,
      },
    });
  } catch (error) { }
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate transaction against default-inquiry process
 * Note: At this point inquiry transition is made directly against Marketplace API.
 *       So, client app's server is not involved here unlike with transitions including payments.
 *
 * @param {*} inquiryParams contains listingId and protectedData
 * @param {*} processAlias 'default-inquiry/release-1'
 * @param {*} transitionName 'transition/inquire-without-payment'
 * @returns
 */
export const initiateInquiryWithoutPayment = (inquiryParams, processAlias, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateInquiryRequest());

  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(initiateInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const bodyParams = {
    transition: transitionName,
    processAlias,
    params: inquiryParams,
  };
  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transactionId = response.data.data.id;
      dispatch(initiateInquirySuccess());
      return transactionId;
    })
    .catch(e => {
      dispatch(initiateInquiryError(storableError(e)));
      throw e;
    });
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  const { orderData: newOrderData = {} } = getState().CheckoutPage;

  const { isCheckoutPage, cart, deliveryMethod } = newOrderData && Object.keys(newOrderData).length
    ? newOrderData
    : orderParams;

  if (!isCheckoutPage) dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { quantity, bookingDates, cart: oldCart, deliveryMethod: oldDeliveryMethods, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { cart, deliveryMethod } : { cart };

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition: transitionName,
      params: transitionParams,
    }
    : {
      processAlias,
      transition: transitionName,
      params: transitionParams,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams?.listingId?.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

/**
 * Creates individual stock reservation transactions for all the listings in
 * the associated transaction's protectedData.cart, and updates the main
 * transaction protected data with the child transaction ids.
 * @param {*} tx Main cart transaction – expects protectedData.cart
 * @param {*} processAlias Cart stock process alias
 * @param {*} cartItemTransitionName Transition to initiate stock transaction
 * @param {*} parentTransitionName Transition to update the main transaction with child transaction ids
 * @returns 'order', i.e. the updated transaction
 */
export const createStockReservationTransactions = (
  tx,
  processAlias,
  cartItemTransitionName,
  parentTransitionName
) => (dispatch, getState, sdk) => {
  const listingsCart = { ...tx.attributes.protectedData.cart };
  delete listingsCart.deliveryMethod;
  // Create transactions with the cart item process to update stock reservations
  if (listingsCart.length == 0) {
    return tx;
  } else {
    const additionalTransactionFns = Object.keys(listingsCart).map(item => {
      const count = listingsCart[item].count;
      // initiate
      const bodyParams = {
        processAlias: processAlias,
        transition: cartItemTransitionName,
        params: {
          listingId: item,
          stockReservationQuantity: count,
          protectedData: {
            parentTransactionId: tx.id.uuid,
          },
        },
      };

      return sdk.transactions.initiate(bodyParams, { expand: true }).then(res => {
        return {
          listingId: item,
          stockTransactionId: res.data.data.id.uuid,
        };
      });
    });

    return Promise.all(additionalTransactionFns)
      .then(txs => {
        const childTransactions = txs.reduce((children, child) => {
          return {
            ...children,
            [child.listingId]: child.stockTransactionId,
          };
        }, {});

        const parentProtectedData = {
          ...tx.attributes.protectedData,
          childTransactions,
        };

        // Update child transaction ids
        const parentBodyParams = {
          id: tx.id,
          transition: parentTransitionName,
          params: {
            protectedData: parentProtectedData,
          },
        };

        return sdk.transactions.transition(parentBodyParams, { expand: true });
      })
      .then(resp => {
        return resp.data.data;
      });
  }
};

/**
 * Updates the specified transaction's child transactions using the specified transition
 * @param {*} tx Main transaction – expects protectedData.childTransactions
 * @param {*} transitionName Transition to apply to protectedData.childTransactions array
 * @returns 'tx', i.e. the main transaction
 */
export const updateStockReservationTransactions = (tx, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  const childTransactions = Object.values(tx.attributes.protectedData.childTransactions);

  const updateFns = childTransactions.map(childTx => {
    const bodyParams = {
      id: childTx,
      transition: transitionName,
      params: {},
    };

    return sdk.transactions.transition(bodyParams).then(resp => {
      return resp.data;
    });
  });

  Promise.all(updateFns);

  return tx;
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
