import mixpanel from 'mixpanel-browser';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BrandingIconCard, ResponsiveImage } from '../../components';
import { mixPanelEventsConstants, mixPanelEventsData, mixPanelEventsScreens } from '../../mixpanel';
import { createResourceLocatorString } from '../../util/routes';
import { DEAL_LISTING_TYPE, PRODUCT_LISTING_TYPE, SERVICE_LISTING_TYPE } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import css from './AlgoliaSearchServiceCard.module.css';
import classNames from 'classnames';
import customerLogo from '../../assets/customerLogo.png';
import IconCollection from '../../components/IconCollection/IconCollection';

const AlgoliaSearchServiceCard = props => {
  const { hit, currentUser, roleName, toggleFavorite, history, routeConfiguration: routes } = props;
  const { title, price, images = [], publicData = {}, author = {} } = hit || {};
  const authorName = author?.displayName || '';
  const { listingType } = publicData || {};
  const ownListing = hit?.author?.id === currentUser?.id?.uuid;
  // Determine the correct wishlist type based on listingType
  const wishlistTypeKey =
    listingType === SERVICE_LISTING_TYPE
      ? 'wishlist_service_ids'
      : listingType === DEAL_LISTING_TYPE
        ? 'wishlist_deal_ids'
        : listingType === PRODUCT_LISTING_TYPE
          ? 'wishlist_product_ids'
          : null;

  const wishlist = currentUser?.attributes?.profile?.publicData?.[wishlistTypeKey] || [];
  const userType = currentUser?.attributes?.profile?.publicData?.userType || '';
  // Check if this listing is already marked as a favorite
  const isFavorite = wishlist.includes(hit?.objectID);

  const id = hit?.objectID;
  const slug = createSlug(title);

  const isBooking = listingType === SERVICE_LISTING_TYPE;

  /**
   * Handles the click event for adding/removing a listing to/from favorites.
   */
  const handleFavoriteClick = () => {
    if (currentUser === null) {
      history.push(createResourceLocatorString('LoginPage', routes, {}, {}));
    } else {
      toggleFavorite(hit?.objectID, listingType, currentUser, hit?.author?.id, title, images);
    }
  };
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  return (
    <div
      className={classNames(css.servicesListBox, arabicLanguage && css.arabicDirection)}
  
    >
      <div className={css.serviceCard}>
       <div className={css.cardContent}
           onClick={() => {
            history.push(createResourceLocatorString('ListingPage', routes, { id, slug }, {}));
            if (currentUser && userType == 'customer') {
              mixpanel.track(
                listingType == 'service'
                  ? mixPanelEventsConstants.BOOK_SERVICE
                  : mixPanelEventsConstants.ADD_PRODUCT,
                {
                  step: listingType == 'service' ? mixPanelEventsData.VIEW_SERVICE : mixPanelEventsData.SELECT_PRODUCT,
                  screenName: mixPanelEventsScreens.ALOGLIA_SEARCH_PAGE,
                  userID: currentUser?.id.uuid,
                  userType: 'Customer',
                }
              );
            }
          }}
       >
       <div className={css.imageWrapper}>
    
         {images[0] ? <ResponsiveImage alt={title} image={images[0]} variants={'custom-image'} /> 
         : <ResponsiveImage alt={title} image={customerLogo} variants={'custom-image'} />}
        </div>
        <div className={css.detailWrapper}>
          <div className={css.titleDetails}>
            <h2 className={css.title}>{title}</h2>
            <div className={css.authorName}>{authorName ? authorName : null}</div>
            <div className={css.priceAmount}>
              <span>{price?.currency}</span> <span>{price?.amount / 100}</span>
            </div>
            <div className={css.reviewDetails}>
            {/* <BrandingIconCard type="star" />
              <BrandingIconCard type="star" />
              <BrandingIconCard type="star" />
              <BrandingIconCard type="star" />
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <span className={css.reviewNumber}>0</span>
              {/* <FormattedMessage id="ListingPage.zeroratings" /> */}

            </div>
          </div>
          <div className={css.buttonWrapper}>
            <button
              type="button"
              className={css.button}
            >
              <FormattedMessage id={isBooking ? 'SearchPage.book' : 'SearchPage.buy'} />
            </button>
          </div>
        </div>
       </div>
       {!ownListing && <span
            className={isFavorite ? css.likeIconWrapper : css.wishListIcon}
            onClick={e => {
              e.preventDefault();
              handleFavoriteClick();
            }}
          >
            <BrandingIconCard type="heart" />
          </span>}
      </div>
    </div>
  );
};

export default AlgoliaSearchServiceCard;
