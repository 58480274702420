import { string } from 'prop-types';
import React from 'react';
import handImage from '../../../../assets/images/hand.png';
import { Button } from '../../../../components';
import BrandingIconCard from '../../../../components/BrandingIconCard/BrandingIconCard';
import { createResourceLocatorString } from '../../../../util/routes';
import TopbarSearchForm from '../../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import css from './CustomerHero.module.css';
import classNames from 'classnames';

/**
 * CustomerHero Component
 *
 * This component serves as a search interface that allows users to find and book services
 * in their vicinity. It provides input fields for service keywords and location, as well as
 * a button to initiate the search.
 *
 * Props:
 * - searchQuery: Current value of the search input for services.
 * - setSearchQuery: Function to update the search query state.
 * - history: History object for programmatic navigation.
 * - routeConfiguration: Configuration object for defining routes.
 * - config: Configuration for the application used in search form.
 * - handleLocationSubmit: Function to handle the submission of location search.
 * - locationQuery: Current value for the location input (not used directly here).
 */
const CustomerHero = ({
  searchQuery,
  setSearchQuery,
  history,
  routeConfiguration,
  config,
  handleLocationSubmit,
  locationQuery,
  intl,
}) => {

  // Determine bounds for the location query if available
  const bounds =
    Object.keys(locationQuery).length > 0
      ? `${locationQuery.location.selectedPlace.bounds.ne.lat},${locationQuery.location.selectedPlace.bounds.ne.lng},${locationQuery.location.selectedPlace.bounds.sw.lat},${locationQuery.location.selectedPlace.bounds.sw.lng}`
      : '';
      const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  return (
    <div className={css.customerHeroWrapper}>
      <div className={classNames(css.customerSearch, arabicLanguage && css.arabicText)}>
        <h2>{intl.formatMessage({ id: "CustomerHero.bestDealHeading" })} <img src={handImage} alt="Hand Image" /></h2>
        <p>{intl.formatMessage({ id: "CustomerHero.findServicesHeading" })}</p>
        <div className={css.searchBar}>
          {/* Service search input */}
          <div className={css.searchServices}>
            <span className={css.searchIcon}>
              <BrandingIconCard type="search" />
            </span>
            {/* Todo: add this on location input */}
            {/* <p>Service Name</p> */}
            <div className={css.searchInput}>
              <label htmlFor='searchQuery'>
                {intl.formatMessage({ id: "CustomerHero.serviceNameLabel" })}
              </label>
              <input
                onChange={e => setSearchQuery(e.target.value)} // Update search query on input change
                name="searchQuery"
                id="searchQuery"
                value={searchQuery} // Controlled input
                type="text"
                placeholder={intl.formatMessage({ id: "CustomerHero.serviceNamePlaceholder" })} // Placeholder text
              />
            </div>
          </div>

          {/* Location search input */}
          <div className={css.searchServices}>
            <span className={css.searchIcon}>
              <BrandingIconCard type="location" />
            </span>
            <div className={css.searchInput}>
              <label htmlFor='searchQuery'>
                {intl.formatMessage({ id: "CustomerHero.locationLabel" })}
              </label>
              <TopbarSearchForm
                className={css.topbarSearch}
                desktopInputRoot={css.topbarSearchWithLeftPadding}
                onSubmit={handleLocationSubmit} // Function to handle location search submission
                initialValues={{}} // Initial values for the search form
                appConfig={config} // Pass app configuration to the form
              />
            </div>
          </div>

          {/* Search button */}
          <div className={css.searchButton}>
            <Button
              onClick={() => {
                // Navigate to the search results page with the search query as a parameter
                history.push(
                  createResourceLocatorString(
                    'AlgoliaSearchPage', // Target page
                    routeConfiguration, // Route configuration
                    { listingType: 'services' }, // Path parameters
                    {
                      keywords: searchQuery,
                      address:
                        Object.keys(locationQuery).length > 0
                          ? locationQuery?.location?.search
                          : '',
                      bounds: bounds,
                    } // Query parameters
                  )
                );
              }}
            >
              {intl.formatMessage({ id: "CustomerHero.search" })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Default props for the component
CustomerHero.defaultProps = {
  className: null,
  rootClassName: null,
};

// Prop types for validation
CustomerHero.propTypes = {
  className: string,
  rootClassName: string,
};

export default CustomerHero;
