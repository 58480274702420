import { string } from 'prop-types';
import React from 'react';
import MobileStore from '../../../../components/MobileStore/MobileStore';
import css from './MerchantHero.module.css';

const MerchantHero = (props) => {
  const { intl } = props;

  return (
    <div className={css.merchantWrapper}>
      <h1 className={css.merchantHeading}>
        {intl.formatMessage({ id: "MerchantHero.manageBookingsHeading" })}
      </h1>
      <p className={css.merchantSubHeading}>
        {intl.formatMessage({ id: "MerchantHero.simpleBusinessSubHeading" })}
      </p>
      <MobileStore className={css.mobileStore} />
    </div>
  )
};

MerchantHero.defaultProps = {
  className: null,
  rootClassName: null,
};

MerchantHero.propTypes = {
  className: string,
  rootClassName: string,
};

export default MerchantHero;
