import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import categoryImage from '../../../../assets/images/offer_logo.png';
import { Button } from '../../../../components';
import IconCollection from '../../../../components/IconCollection/IconCollection';
import { transformCategories } from '../../../../util/data';
import { categoriesListing } from '../../LandingPage.duck';
import styles from './TopCategories.module.css';

import classNames from 'classnames';
import { createResourceLocatorString } from '../../../../util/routes';

/**
 * TopCategories component fetches and displays the top categories.
 * It transforms the flat category data into a hierarchical structure.
 */
const TopCategories = (props) => {
  const {
    intl,
    history,
    routeConfiguration
  } = props;
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);

  // Fetch category data when the component mounts
  useEffect(() => {
    const fetchCategoriesData = () => {
      try {
        dispatch(categoriesListing())
          .then(data => {
            setCategories(data); // Store fetched categories in state
          });
      } catch (error) {
        console.error(intl.formatMessage({ id: "AddDealForm.failedToFetchCategories" }), error);
      }
    };

    fetchCategoriesData(); // Call the fetch function
  }, [dispatch]);

  // Transform the fetched categories into a hierarchical structure
  const organizedCategories = transformCategories(categories);

  //change the categoryImage to category and below text -> text is not exceed above 1 line
  //in one line add show 8 categories
  return (
    <div className={classNames(styles.topCategoriesWrapper, arabicLanguage && styles.arabicText)}>
      <div className={classNames(styles.topCategoriesHeading)}>
        {intl.formatMessage({ id: "TopCategories.heading" })}
      </div>
      <div className={styles.categoriesCard}>
        {Array.isArray(organizedCategories) && organizedCategories.length > 0 ? (
          <>
            {organizedCategories.slice(0, 1).map(categoryItem =>
              categoryItem.childCategory.slice(0, 23).map(childCategory => (
                <div
                  key={childCategory.id}
                  className={styles.categoriesList}
                  onClick={() => {
                    history.push(
                      createResourceLocatorString(
                        'AlgoliaSearchPage',
                        routeConfiguration,
                        { listingType: "services" },
                        { "publicData.categoryLevel2": childCategory?.id }
                      )
                    );
                  }}
                >
                  <div className={styles.categoriesImage}>
                    <img src={categoryImage} alt={`Category: ${childCategory.label}`} />
                  </div>
                  <div className={styles.categoriesTitle}>
                    {intl.formatMessage({ id: `category.${childCategory.id}` })}
                  </div>
                </div>
              ))
            )}
            <div
              className={styles.categoriesList}
              onClick={() => {
                history.push(
                  createResourceLocatorString(
                    'AlgoliaSearchPage',
                    routeConfiguration,
                    { listingType: "services" },
                    {}
                  )
                );
              }}
            >
              <div className={styles.categoriesImage}>
                <div className={styles.viewAllCircle}>
                  <IconCollection name="buttonArrow" />
                </div>
              </div>
              <Button className={classNames(styles.categoriesTitle, styles.viewAllButton)}>
                {intl.formatMessage({ id: "TopCategories.viewAllButtonLabel" })}
              </Button>
            </div>
          </>
        ) : (
          // Display message if no categories are available
          <div className={styles.noCategories}>
            {intl.formatMessage({ id: "TopCategories.noCategoriesAvailable" })}
          </div>
        )}
      </div>
    </div>
  );
};

// Default props for the component
TopCategories.defaultProps = {
  className: null,
  rootClassName: null,
};

// Prop types for type checking
TopCategories.propTypes = {
  className: string,
  rootClassName: string,
};

export default TopCategories;
