import React from 'react';



import css from './Loader.module.css';

// Due to the layout structure, do not render the banner on the following pages


const Loader =()=> {
  return(
    <div className={css.loader_overlay}>
    <div className={css.loader}></div>
  </div>
  )
};



export default Loader;
