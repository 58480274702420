import { string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BrandingIconCard } from '../../../../components';
import css from './NewsLetter.module.css';
import classNames from 'classnames';

const NewsLetter = (props) => {
    const { intl } = props;
    const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";
    return (
        <div className={classNames(css.newsLetterWrapper, arabicLanguage && css.arabicDirection)}>
            <div className={css.newsletterBox}>
                <div className={css.joinNewsletter}>
                    <FormattedMessage id="NewsLetter.joinOurNewsletter" />
                </div>
                <div className={css.productPromotion}>
                    <FormattedMessage id="NewsLetter.signupForPromotions" />
                </div>
                <div className={css.newsletterInput}>
                    <span className={css.emailIcon}>
                        <BrandingIconCard type="email" />
                    </span>
                    <input type="email" placeholder={intl.formatMessage({ id: "NewsLetter.emailPlaceholder" })} />
                    <span className={css.signupLink}>
                        <FormattedMessage id="NewsLetter.signupButtonLabel" />
                    </span>
                </div>
            </div>
        </div>
    )
};

NewsLetter.defaultProps = {
    className: null,
    rootClassName: null,
};

NewsLetter.propTypes = {
    className: string,
    rootClassName: string,
};

export default NewsLetter;
