import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import axios from 'axios';
import { apiBaseUrl, onCreateBusiness } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StripePayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

// Upload file to tap
export const uploadFileToTap = async (data) => {
  const { purpose, title, expires_at, file_link_create, file } = data;

  try {
    const formData = new FormData();
    formData.append('purpose', purpose);
    formData.append('file', file);
    formData.append('title', title);
    formData.append('expires_at', expires_at);
    formData.append('file_link_create', file_link_create);

    const url = apiBaseUrl() + `/api/upload-file-to-tap`;

    const response = await axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // Check if response contains an error field
    if (response.data && response.data.error) {
      throw new Error(`${response.data.error}`);
    }
    return response.data;
  } catch (error) {
    // Handle axios or server errors
    if (error.response) {
      // Server error (e.g., 4xx/5xx status codes)
      throw new Error(`${error.response.data.message || 'Unknown error'}`);
    } else if (error.request) {
      // No response received (e.g., network issue)
      throw new Error('No response received from the server');
    } else {
      // Other errors (e.g., axios config issues)
      throw new Error(`Error during file upload: ${error.message}`);
    }
  }
};

// create business in Tap
export const createTapBusiness = (data) => async (dispatch, getState, sdk) => {
  try {
    const response = await onCreateBusiness(data);
    const businessData = response?.data || response;

    // Check for business data and essential properties
    if (businessData && businessData.id && businessData.destination_id) {
      // Dispatch the updateProfile action
      await dispatch(
        updateProfile({
          publicData: {
            destinationId: businessData.destination_id,
            businessId: businessData.id,
          },
        })
      );

      // Return the business data
      return businessData;
    } else {
      // If the expected data is not available, throw an error
      throw new Error('Missing destination_id or id in response');
    }
  } catch (error) {
    // Detailed error handling
    if (error.response) {
      // Handle server-side errors (e.g., 4xx/5xx status codes)
      throw new Error(`${error.response.data.message || 'Unknown error'}`);
    } else if (error.request) {
      // Handle cases where no response is received (e.g., network issues)
      throw new Error('No response received from the server');
    } else {
      // Handle any other errors (e.g., Axios configuration issues)
      throw new Error(`${error.message || 'Unknown error'}`);
    }
  }
};


// ================ Thunks ================ //

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.stripeAccount) {
      dispatch(fetchStripeAccount());
    }
    return response;
  });
};
