// import { QRCodeVerification, VerifyOtp } from "../screens";

export const mixpanelConstants = {};

export const mixpanelEvents = {
  SIGNUP: {
    LABEL: 'signup',
    SIGNIN_METHOD: 'signin_method',
    APPLE: 'Apple',
    GOOGLE: 'Google',
    PHONE: 'Phone',
    EMAIL: 'Email',
  },
  LOGIN: {
    LABEL: 'login',
    LOGIN_METHOD: 'login_method',
    APPLE: 'Apple',
    GOOGLE: 'Google',
    PHONE: 'Phone',
    EMAIL: 'Email',
  },
};

export const mixPanelEventsConstants ={
  ADD_SERVICES:'add_services',
  TRANSACTION_COMPLETION_SUCCESS:'transaction_completion_success',
  VIEW_BOOKINGS_DETAILS_INPUT_CODE:'view_bookings_details_input_code',
  VIEW_BOOKINGS_DETAILS_SCAN_QR:'view_bookings_details_scan_QR',
  VIEW_BOOKINGS:'view_bookings',
  VIEW_BOOKINGS_DETAILS:'view_booking_details',
  ADD_DEAL:'add_deal',
  VIEW_DEAL:'view_deal',
  VIEW_DEAL_TYPE:'view_deal_type',
  VIEW_DEAL_TYPE_INPUT_CODE:'view_deal_type_input_code',
  VIEW_DEAL_TYPE_SCAN_QR:'view_deal_type_scan_QR',
  VIEW_DEAL_TYPE_VIEW_DETAILS:'view_deal_type_view_details',
  USER_ONBOARDING:'user_onboarding',
  ADD_PRODUCT:'add_product',
  VIEW_MAIN_DASHBOARD:'view_main_dashboard',
  VIEW_REVENUE:'view_revenue',
  CLICK_BANNER:'click_banner',
  ACCESS_USER_PROFILE:'access_user_profile',
  ACCESS_WALLET:'access_wallet',
  VIEW_NOTIFICATIONS:'view_notifications',
  USER_FIRST_LOGIN:'user_first_login',
  VIEW_MORE:'view_more',
  VIEW_MERCHANT_PROFILE:'view_merchant_profile',
  BUSINESS_OBJECTIVE:'business_objective',
  VIEW_PRODUCTS:'view_products',
  MERCHANT_PROFILE_COMPLETE:'merchant_profile_complete',
  MERCHANT_PROFILE_BUSINESS:'merchant_profile_business',
  MERCHANT_PROFILE_PERSONAL:'merchant_profile_personal',
  MERCHANT_PROFILE_SETUP:'merchant_profile_setup',
  COMPLETE_TRANSACTION_QR:'complete_transaction_QR',
  COMPLETE_TRANSACTION_CODE:'complete_transaction_Code',
  CREATE_BUZZ:'create_buzz',
  SEND_BUZZ:'send_buzz',
  USER_SIGNED_UP:'user_signed_up',
  OTP_VERIFIED:'otp_verified',
  ONBOARDING:'onboarding',
  USER_SIGNED_IN:'user_signed_in',
  GUEST_SIGN_IN:'guest_sign_in',
  GO_HOME:'go_home',
  VIEW_DEALS:'view_deals',
  VIEW_ORDERS:'view_orders',
  GLOBAL_SEARCH:'global_search',
  VIEW_BOOK_APPOINTMENT:'view_book_appointment',
  VIEW_SERVICES:'view_services',
  VIEW_SERVICES_ALL:'view_services_all',
  VIEW_SERVICES_CATEGORIES:'view_services_categories',
  VIEW_SERVICES_MERCHANTS:'view_services_merchants',
  BOOK_SERVICE:'book_service',
  VIEW_SELECT_CATEGORY_ITEMS:'view_select_category_items',
  VIEW_MERCHANT_PROFILE_ABOUT:'view_merchant_profile_about',
  VIEW_MERCHANT_PROFILE_SERVICES:'view_merchant_profile_services',
  VIEW_MERCHANT_PROFILE_DEALS:'view_merchant_profile_deals',
  VIEW_MERCHANT_PROFILE_PRODUCTS:'view_merchant_profile_products',
  SELECT_STAFF:'select_staff',
  SEND_MERCHANT_CHAT:'send_merchant_chat',
  VIEW_DEALS_ALL:'view_deals_all',
  VIEW_DEALS_CATEGORIES:'view_deals_categories',
  VIEW_DEAL_MERCHANTS:'view_deal_merchants',
  OPEN_DEAL:'open_deal',
  VIEW_ORDERS_LIST:'view_orders_list',
  VIEW_PRODUCT_ORDERS_INFO:'view_product_orders_info',
  VIEW_BOOKING_ORDERS_INFO:'view_booking_orders_info',
  VIEW_BOOKINGS_DETAILS:'view_bookings_details',
  VIEW_BOOKINGS_CALENDAR_TIMEFRAME:'view_bookings_calendar_timeframe',
  VIEW_BOOKINGS_CALENDAR:'view_bookings_calendar'
}

export const mixPanelEventsScreens={
  VERIFYOTP:'MerchantDetails',
  SIGNUP:'AuthenticationPage',
  SENDBUZZ:'SendBuzz',
  QRCODEVERIFICATION:'QRCodeVerification',
  PROFILESETUPTAB:'ProfileSetupTab',
  PERSONALTAB:'PersonalTab',
  BUSINESSTAB:'BusinessTab',
  PROFILEBASICINFO:'ProfileBasicInfo',
  DEAL:'Deal',
  PRODUCTS:'Products',
  ORDERTRACKING:'OrderTracking',
  CUSTOMISEPROFILE:'CustomiseProfile',
  MORESCREENS:'MoreActionPage',
  LOGIN:'Login',
  LISTING:'Listing',
  HOME:'Home',
  SERVICES:'Services',
  BOOKINGDETAIL:'BookingDetail',
  BOOKINGS:'CalendarPage',
  TODAYTIMELINE:'TodayTimeline',
  WEEKTIMELINE:'WeekTimeline',
  DEALS:'Deals',
  EDITDEALS:'EditDeals',
  EDITLISITING:'EditLisitingPage',
  TODAY_HOT_DEALS: 'TodayHotDeals',
  REQUEST_LOCATION:'RequestLocation',
  REQUEST_MANUAL_LOCATION:'RequestManuallLocation',
  INTEREST_SELECTION:'InterestSelection',
  ORDERS:'Orders',
  MORE:'More',
  PRODUCT_TAB:'ProductTab',
  MERCHANT_PROFILE:'ViewBusinessPage',
  FIND_DEAL:'FindDeal',
  BOOKING_DETAILS:'BookingDetails',
  MERCHANT_ONBOARDING:'MerchantOnboarding',
  VIEW_LISTING:'ViewListing',
  DEALS_PAGE:'DealsPage',
  ALOGLIA_SEARCH_PAGE:'AlgoliaSearchPage',
  INBOX_PAGE:'InboxPage',
  TRANSACTION_PAGE:'TransactionPage',
}

export const mixPanelEventsData={
  UPDATE_BUZZ_DETAILS:'update_buzz_details',
  SEND_BUZZ_SUCCESS:'send_buzz_success',
  OTHER_BIZ_INFO:'other_biz_info',
  FIRST_DEAL_SUCCESS:'first_deal_success',
  CREATE_PRODUCT:'create_product',
  CREATE_SERVICES_SUCCESS:'create_services_success',
  CREATE_PRODUCT_SUCCESS:'create_product_success',
  VIEW_ONBOARDING_DASHBOARD:'view_onboarding_dashboard',
  CREATE_SERVICES:'create_services',
  CREATE_DEAL_SUCCESS:'create_deal_success',
  INITIATE_CREATE_DEAL:'initiate_create_deal',
  CREATE_FIRST_DEAL:'create_first_deal',
  CREATE_DEAL_ADD_DETAILS:'create_deal_add_details',
  SELECT_DEAL_TYPE:'select_deal_type',
  ADD_ITEM:'add_item',
  CREATE_SERVICES_ADD_ITEM:'create_services_add_item',
  CREATE_PRODUCT_ADD_ITEMS:'create_product_add_items',
  VIEW_MORE_DEALS:'view_more_deals',
  SWIPE_LEFT:'swipe_left',
  SWIPE_RIGHT:'swipe_right',
  SIGN_UP:'sign_up',
  ADD_LOCATION:'add_location',
  SELECT_INTERESTS:'select_interests',
  VIEW_WELCOME_MODAL:'view_welcome_modal',
  CLICK_WELCOME_BANNER:'click_welcome_banner',
  VIEW_SERVICE:'view_service',
  SELECT_TIMESLOT:'select_timeslot',
  ADD_TO_CART:'add_to_cart',
  REMOVE_FROM_CART:'remove_from_cart',
  SELECT_PRODUCT:'select_product',
  REMOVE_PRODUCT_FROM_CART:'remove_product_from_cart',
  ADD_PRODUCT_TO_CART:'add_product_to_cart',
  TIMEFRAME:'timeframe',

}