import { string } from 'prop-types';
import React, { useState } from 'react';
import { OutsideClickHandler, RangeSlider } from '../../../../components';
import css from './PopularDeals.module.css';
import { classNames } from 'primereact/utils';
// import ServicesCard from './ServicesCard';

const PopularDeals = props => {
  const { dealFilterParams, setDealFilterParams, organizedDealCategories, intl } = props;
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  const [showPriceRange, setShowPriceRange] = useState(false);
  const [category, setCategory] = useState('');
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000 });
  const [currLocation, setCurrLocation] = useState({});
  const [nearby, setNearby] = useState('');
  const handleCategoryChange = e => {
    const newCategory = e.target.value;
    setCategory(newCategory);
    updateDealFilterParams(newCategory, priceRange, currLocation);
  };

  // Handle price range change
  const handlePriceRangeChange = handles => {
    const newPriceRange = { min: handles[0], max: handles[1] };
    setPriceRange(newPriceRange);
    updateDealFilterParams(category, newPriceRange, currLocation);
  };

  // Handle nearby location change
  const handleNearBySubmit = e => {
    const selectedNearby = e.target.value;
    setNearby(selectedNearby);

    if (selectedNearby === 'current') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            const location = { lat: parseFloat(latitude.toFixed(3)), lng: parseFloat(longitude.toFixed(3)) };
            setCurrLocation(location);
            updateDealFilterParams(category, priceRange, location);
          },
          error => {
            console.error(intl.formatMessage({ id: "PopularDeals.errorFetchingLocation" }), error);
          }
        );
      }
    } else {
      const resetLocation = {};
      setCurrLocation(resetLocation);
      updateDealFilterParams(category, priceRange, resetLocation);
    }
  };
  const updateDealFilterParams = (newCategory, newPriceRange, newLocation) => {
    setDealFilterParams({
      category: newCategory || null,
      priceRange: { min: newPriceRange.min, max: newPriceRange.max },
      location: newLocation || null,
    });

  };
  return (
    <div className={classNames(css.popularDealsWrapper , arabicLanguage && css.arabicRowReverse)}>
      <div className={classNames(css.polularBox)}>
        <div className={css.servicesHeading}>
          {intl.formatMessage({ id: "PopularDeals.popularDealsHeading" })}
        </div>
        <div className={css.searvicesFilter}>
          <div className={css.selectBox}>
            {organizedDealCategories?.length > 0 && (
              <select
                value={category}
                onChange={handleCategoryChange}
              // className={css.dropdown}
              >
                <option value="">
                  {intl.formatMessage({ id: "LatestServices.categoriesFilter" })}
                </option>
                {organizedDealCategories.slice(0, 1).map(value => (
                  <option key={value.id} value={value.id}>
                    {intl.formatMessage({ id: `dealCategory.${value.id}` })}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className={css.selectBox}>
            <div className={css.priceRange}>
              <label onClick={() => setShowPriceRange(true)}>
                {intl.formatMessage({ id: "LatestServices.budgetFilter" })}
              </label>
              <OutsideClickHandler onOutsideClick={() => setShowPriceRange(false)}>
                {showPriceRange && (
                  <div className={css.priceInput}>
                    <div>
                      <RangeSlider
                        min={0}
                        max={10000}
                        step={100}
                        handles={[priceRange.min, priceRange.max]}
                        onChange={handlePriceRangeChange}
                      />
                    </div>
                    <span>
                      {priceRange.min} - {priceRange.max}
                    </span>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
          </div>

          <div className={css.selectBox}>
            <select
              value={nearby}
              onChange={e => handleNearBySubmit(e)}
            // className={css.dropdown}
            >
              <option value="">
                {intl.formatMessage({ id: "LatestServices.nearByFilter" })}
              </option>
              <option value="current">
                {intl.formatMessage({ id: "LatestServices.currentLocation" })}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className={css.popularBody}>
    
        <div className={css.discountVoucher}>
        <div className={css.discountVoucherImage}>
        </div>
         <div className={css.voucherContent}>
         <div className={css.fromTopText}>{intl.formatMessage({ id: "PopularDeals.fromTop" })}</div>
          <div className={css.standardText}>
            {intl.formatMessage({ id: "PopularDeals.getUpTo" })}
          </div>
          <div className={css.largeDiscount}>60%</div>
          <div className={css.standardText}>
            {intl.formatMessage({ id: "PopularDeals.discountOnDeals" })}
          </div>
         </div>
        </div>
      </div>
      {/* <div className={css.popularCardGrid}>
                <PopularDealsCard />
            </div> */}
    </div>
  );
};

PopularDeals.defaultProps = {
  className: null,
  rootClassName: null,
};

PopularDeals.propTypes = {
  className: string,
  rootClassName: string,
};

export default PopularDeals;
