import { string } from 'prop-types';
import React from 'react';
import numberImage from '../../../../assets/100text.png';
import slotImage from '../../../../assets/images/slotImage.png';
import { ResponsiveImage } from '../../../../components';
import MobileStore from '../../../../components/MobileStore/MobileStore';
import css from './SlotsSection.module.css';

const SlotsSection = (props) => {
  const { intl } = props;

  return (
    <div className={css.slotWrapper}>
      <div className={css.slotRow}>
        <div className={css.slotLeft}>
          <h2>
            {intl.formatMessage({ id: "SlotsSection.noMoreEmptySlots" })}
            <ResponsiveImage image={numberImage} variants={"custom-image"} /> {intl.formatMessage({ id: "SlotsSection.capacity" })}
          </h2>
          <p>
            {intl.formatMessage({ id: "SlotsSection.createManageSubHeading" })}
          </p>
          <MobileStore className={css.slotMobileStore} />
        </div>
        <div className={css.slotRight}>
          <div className={css.bottomleft}>
            <ResponsiveImage
              alt="slot Image"
              image={slotImage}
              variants={"custom-image"}
              className={css.slotImage}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

SlotsSection.defaultProps = {
  className: null,
  rootClassName: null,
};

SlotsSection.propTypes = {
  className: string,
  rootClassName: string,
};

export default SlotsSection;
