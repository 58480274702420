import { getAlgoliaObject, onGetDealsCategoriesList, searchAlgoliaData } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/ViewBusinessPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/ViewBusinessPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/ViewBusinessPage/SEARCH_LISTINGS_ERROR';
export const ALGOLIA_SEARCH_RESULTS = 'app/ViewBusinessPage/ALGOLIA_SEARCH_RESULTS';

export const DEALS_CATEGORIES_REQUEST = 'app/ViewBusinessPage/DEALS_CATEGORIES_REQUEST';
export const DEALS_CATEGORIES_SUCCESS = 'app/ViewBusinessPage/DEALS_CATEGORIES_SUCCESS';
export const DEALS_CATEGORIES_ERROR = 'app/ViewBusinessPage/DEALS_CATEGORIES_ERROR';

export const FETCH_BUSINESS_REQUEST = 'app/ViewBusinessPage/FETCH_BUSINESS_REQUEST';
export const FETCH_BUSINESS_SUCCESS = 'app/ViewBusinessPage/FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_ERROR = 'app/ViewBusinessPage/FETCH_BUSINESS_ERROR';

export const FETCH_STAFFS_REQUEST = 'app/ViewBusinessPage/FETCH_STAFFS_REQUEST';
export const FETCH_STAFFS_SUCCESS = 'app/ViewBusinessPage/FETCH_STAFFS_SUCCESS';
export const FETCH_STAFFS_ERROR = 'app/ViewBusinessPage/FETCH_STAFFS_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchParams: null,
  pagination: null,
  searchInProgress: false,
  searchListings: [],
  searchListingsError: null,
  algoliaSearchResults: [],
  dealsCategoriesRequest: false,
  dealsCategories: [],
  dealsCategoriesError: null,
  businessListing: null,
  fetchBusinessInProgress: false,
  fetchBusinessError: null,
  staffListings: [],
  fetchStaffsInProgress: false,
  fetchStaffsError: null,
};

const ViewBusinessPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ALGOLIA_SEARCH_RESULTS:
      return {
        ...state,
        algoliaSearchResults: payload,
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload,
        searchInProgress: true,
        pagination: null,
        searchListings: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
        searchListings: [],
        pagination: null,
      };

    case DEALS_CATEGORIES_REQUEST:
      return {
        ...state,
        dealsCategoriesRequest: true,
        dealsCategoriesSuccess: [],
      };

    case DEALS_CATEGORIES_SUCCESS:
      return {
        ...state,
        dealsCategoriesRequest: false,
        dealsCategoriesSuccess: payload,
      };

    case DEALS_CATEGORIES_ERROR:
      return {
        ...state,
        dealsCategoriesError: payload,
      };

    case FETCH_BUSINESS_REQUEST:
      return {
        ...state,
        fetchBusinessInProgress: true,
        fetchBusinessError: null,
      };

    case FETCH_BUSINESS_SUCCESS:
      return {
        ...state,
        fetchBusinessInProgress: false,
        businessListing: payload,
      };

    case FETCH_BUSINESS_ERROR:
      return {
        ...state,
        fetchBusinessError: payload,
      };

    // Staffs
    case FETCH_STAFFS_REQUEST:
      return {
        ...state,
        fetchStaffsInProgress: true,
        fetchStaffsError: null,
      };

    case FETCH_STAFFS_SUCCESS:
      return {
        ...state,
        fetchStaffsInProgress: false,
        staffListings: payload,
      };

    case FETCH_STAFFS_ERROR:
      return {
        ...state,
        fetchStaffsError: payload,
      };

    default:
      return state;
  }
};

export default ViewBusinessPageReducer;

// ================ Action creators ================ //

export const categoriesRequest = () => ({
  type: DEALS_CATEGORIES_REQUEST,
});

export const categoriesSuccess = payload => ({
  type: DEALS_CATEGORIES_SUCCESS,
  payload: payload,
});

export const categoriesError = response => ({
  type: DEALS_CATEGORIES_ERROR,
  payload: response,
});

export const algoliasearchResults = data => ({
  type: ALGOLIA_SEARCH_RESULTS,
  payload: data,
});
export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: searchParams,
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: response,
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

/**
 * Trigger fetching of business details
 */
export const fetchBusinessRequest = () => ({
  type: FETCH_BUSINESS_REQUEST,
});

export const fetchBusinessSuccess = response => {
  return ({
    type: FETCH_BUSINESS_SUCCESS,
    payload: response,
  })
};

export const fetchBusinessError = e => ({
  type: FETCH_BUSINESS_ERROR,
  error: true,
  payload: e,
});

/**
 * Trigger fetching of staffs
 */
export const fetchStaffsRequest = () => ({
  type: FETCH_STAFFS_REQUEST,
});

export const fetchStaffsSuccess = response => {
  return ({
    type: FETCH_STAFFS_SUCCESS,
    payload: response,
  })
};

export const fetchStaffsError = e => ({
  type: FETCH_STAFFS_ERROR,
  error: true,
  payload: e,
});


export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  try {
    //fetching result in-progress
    dispatch(searchListingsRequest());
  } catch (error) {
    console.error('error..', error);
    dispatch(searchListingsSuccess());
  }
};

export const getAlgoliasearchResults = params => (dispatch, getState, sdk) => {
  try {
    dispatch(algoliasearchResults(params));
  } catch (error) { }
};

export const categoriesListing = () => async dispatch => {
  dispatch(categoriesRequest);
  try {
    const response = await onGetDealsCategoriesList();
    dispatch(categoriesSuccess(response));
    return response;
  } catch (error) {
    dispatch(categoriesError(error.message));
  }
};

export const fetchAuthorData = ({ userId }) => async (dispatch, getState, sdk) => {
  try {
    const result = await sdk.users.show({ id: new UUID(userId) });
    return result?.data?.data;
  } catch (error) { }
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const { listingType, id: listingId } = params;

  if (listingId) {
    dispatch(fetchBusinessRequest());
    getAlgoliaObject({ indexName: process.env.REACT_APP_ALGOLIA_BUSINESS_INDEX, objectID: listingId })
      .then(res => {
        const businessListing = res?.data;
        dispatch(fetchBusinessSuccess(businessListing));
        const authorId = businessListing?.author?.id;

        if (authorId) {
          dispatch(fetchStaffsRequest());
          searchAlgoliaData({
            indexName: process.env.REACT_APP_ALGOLIA_STAFF_INDEX,
            querySearch: "",
            filters: {
              // facets: ["publicData.categoryLevel2"],
              filters: `author.id:${authorId}`,
              // page: 0,
              // hitsPerPage: 0,
            }
          })
            .then(res => {
              const staffs = res?.data?.hits || [];
              dispatch(fetchStaffsSuccess(staffs));
            })
            .catch(e => {
              console.log("error :>> ", e);
              dispatch(fetchStaffsError(e));
            })
        }
      })
      .catch(e => {
        console.log("error :>> ", e);
        dispatch(fetchBusinessError(e));
      })
  }
  return Promise.all([dispatch(categoriesListing()), dispatch(searchListings())]);
};
