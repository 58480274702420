import { fetchCurrentUser } from '../../ducks/user.duck';
import { onGeneratePaymetStatus } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// Action Types
export const PAYMENT_STATUS_REQUEST = 'app/PaymentStatusPage/PAYMENT_STATUS_REQUEST';
export const PAYMENT_STATUS_SUCCESS = 'app/PaymentStatusPage/PAYMENT_STATUS_SUCCESS';
export const PAYMENT_STATUS_ERROR = 'app/PaymentStatusPage/PAYMENT_STATUS_ERROR';

// Initial state for the payment status reducer
const initialState = {
  loading: false,
  data: null,
  error: null,
};

/**
 * Payment status reducer to handle different payment status states.
 * @param {Object} state - The current state of the payment status.
 * @param {Object} action - The action dispatched.
 * @returns {Object} - The new state after applying the action.
 */
const paymentStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Reset error when making a new request
      };
    case PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case PAYMENT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default paymentStatusReducer;

/**
 * Action to trigger a payment status request.
 * @returns {Object} - The action object.
 */
export const paymentStatusRequest = () => ({
  type: PAYMENT_STATUS_REQUEST,
});

/**
 * Action for a successful payment status response.
 * @param {Object} data - The data returned from the successful request.
 * @returns {Object} - The action object with the data payload.
 */
export const paymentStatusSuccess = data => ({
  type: PAYMENT_STATUS_SUCCESS,
  payload: data,
});

/**
 * Action for a failed payment status request.
 * @param {String} error - The error message.
 * @returns {Object} - The action object with the error payload.
 */
export const paymentStatusError = error => ({
  type: PAYMENT_STATUS_ERROR,
  payload: error,
});

/**
 * Thunk action to load payment status data.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export const loadData = (params, search, config) => (dispatch, getState) => {
  const queryParams = parse(search);
  const { tap_id } = queryParams || {};

  dispatch(paymentStatusRequest());
  return onGeneratePaymetStatus({ paymentId: tap_id })
    .then(res => {
      const { id: tapCustomerId } = res?.customer || {};
      const { id: paymentAgreementId, contract } = res?.payment_agreement || {};
      const cardId = contract?.id;

      dispatch(fetchCurrentUser());
      const currentUser = getState().user?.currentUser || {};
      const oldCards = currentUser?.attributes?.profile?.protectedData?.cards || [];

      const newCard = {
        paymentAgreementId,
        cardId,
      }

      const newCardMaybe = oldCards.find(card => card.cardId === cardId) ? [] : [newCard];

      const updatedCards = [
        ...oldCards,
        ...newCardMaybe,
      ];

      dispatch(updateProfile({
        publicData: { customerId: tapCustomerId },
        protectedData: { cards: updatedCards }
      }));
      dispatch(paymentStatusSuccess(res?.status));
    })
    .catch(err => {
      dispatch(paymentStatusError('Error loading payment status data!', err));
    });
};
