import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import featureSection from '../../../assets/images/Features-Section.png';
import { LayoutSingleColumn, Page, ResponsiveImage } from '../../../components';
import { isScrollingDisabled } from '../../../ducks/ui.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import FooterContainer from '../../FooterContainer/FooterContainer';
import { getMetadata } from '../../PageBuilder/PageBuilder';
import TopbarContainer from '../../TopbarContainer/TopbarContainer';
import BestDeals from './BestDeals/BestDeals';
import MerchantHero from './MerchantHero/MerchantHero';
import css from './MerchantHomePage.module.css';
import SlotsSection from './SlotsSection/SlotsSection';
import classNames from 'classnames';
import featureImage1 from '../../../assets/images/featureImage1.png';
import featureImage2 from '../../../assets/images/featureImage2.png';
import featureImage3 from '../../../assets/images/featureImage3.png';
import featureImage4 from '../../../assets/images/featureImage4.png';

export const MerchantHomePage = props => {
  const scrollingDisabled = useSelector(isScrollingDisabled);
  const { intl, pageAssetsData, merchantMaybe } = props;
  const meta = pageAssetsData?.meta ? { ...pageAssetsData.meta } : {};
  const pageMetaProps = getMetadata(meta);
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  return (
    <Page {...pageMetaProps} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn
        className={css.merchantMainWrapper}
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer merchantMaybe={merchantMaybe} />}
        footer={<FooterContainer />}
      >
        <div className={classNames(css.mainWrapper, arabicLanguage && css.arabicDirection)}>
          <div className={css.innerWrapper}>
            <MerchantHero intl={intl} />
            {/* <div className={css.calenderImage}>
              <ResponsiveImage
                alt={"Calender Image"}
                image={imageCalender}
                variants={"custom-image"}
              />
            </div> */}
            <iframe
              className={css.youtubeVideo}
              width="100%"
              height="665"
              src="https://www.youtube.com/embed/hqzvHfy-Ij0?si=IIP8PMq1WfidR-_B"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen>
            </iframe>

            <SlotsSection intl={intl} />
            <BestDeals intl={intl} />
            <div className={css.featureSection}>
              {/* <ResponsiveImage
                alt={"feature Section Image"}
                image={featureSection}
                variants={"custom-image"}
              /> */}
              <h1 className={css.title}><FormattedMessage id='MerchantHomePage.moreTime' /></h1>
              <p className={css.subTitle}><FormattedMessage id='MerchantHomePage.beControl' /></p>
              <div className={css.featureBlocks}>
                <div className={css.featureBlock}>
                  <div className={css.textContent}>
                    <h3 className={css.blockTitle}><FormattedMessage id='MerchantHomePage.automaticallyResolve' /></h3>
                    <p className={css.blockText}><FormattedMessage id='MerchantHomePage.automaticallyBlock' /></p>
                  </div>
                  <ResponsiveImage
                    alt={"feature Section Image"}
                    image={featureImage1}
                    variants={"custom-image"}
                  />
                </div>
                <div className={css.featureBlock}>
                  <div className={css.textContent}>
                    <h3 className={css.blockTitle}><FormattedMessage id='MerchantHomePage.onClick' /></h3>
                    <p className={css.blockText}><FormattedMessage id='MerchantHomePage.accessAll' /></p>
                  </div>
                  <ResponsiveImage
                    alt={"feature Section Image"}
                    image={featureImage2}
                    variants={"custom-image"}
                  />
                </div>
              </div>
              <div className={classNames(css.featureBlocks, css.featureBlocksTwo)}>
                <div className={css.featureBlock}>
                  <div className={css.textContent}>
                    <h3 className={css.blockTitle}><FormattedMessage id='MerchantHomePage.oneSchedule' /></h3>
                    <p className={css.blockText}><FormattedMessage id='MerchantHomePage.reclaimTime' /></p>
                  </div>
                  <ResponsiveImage
                    alt={"feature Section Image"}
                    image={featureImage3}
                    variants={"custom-image"}
                  />
                </div>
                <div className={css.featureBlock}>
                  <div className={css.textContent}>
                    <h3 className={css.blockTitle}><FormattedMessage id='MerchantHomePage.automatedCross' /></h3>
                    <p className={css.blockText}><FormattedMessage id='MerchantHomePage.yourPrivate' /></p>
                  </div>
                  <ResponsiveImage
                    alt={"feature Section Image"}
                    image={featureImage4}
                    variants={"custom-image"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

MerchantHomePage.defaultProps = {
  currentUser: null,
  verificationError: null,
};

MerchantHomePage.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  submitVerification: func.isRequired,
  isVerified: bool,
  emailVerificationInProgress: bool.isRequired,
  verificationError: propTypes.error,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(MerchantHomePage);
